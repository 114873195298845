import View from "../../../../../vendor/framework/view";
import Form from "../../layout/form";
import SeoService from "../../../services/seo";

export default class SeoEdit extends View
{
    boot() {
        this.state = {
            model:{
                type:0,
                title:'',
                keywords:'',
                description:''
            },
            types:[]
        }
    }

    service() {
        return SeoService;
    }

    componentDidMount() {
        this.server.getDetail(this.getPropsParams('id'), res => {
            this.setModel(res.data);
        })
        this.server.getTypes(res => {
            this.setState({
                types:res.data
            })
        })
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={{
                    type:{name:'页面', type:'select', data:this.state.types, option:['id', 'name']},
                    title:'标题',
                    keywords: {name:'关键字', type: 'textarea'},
                    description:{name:'关键字', type: 'textarea'}
                }}
            />
        );
    }
}
