import Base from "../../../base";
import BannerListLayout from "../layout";

class BannerList extends Base
{
    render() {
        return (
            <BannerListLayout />
        );
    }
}
export default BannerList;
