import View from "../../../../../../vendor/framework/view";
import RoleService from "../../../../services/admin/role";
import RuleService from "../../../../services/admin/rule";
import style from "../../rule/list/index.module.css";
import { Fragment } from 'react';

class RoleAdd extends View
{
    boot() {
        this.state = {
            model:{
                name:'',
                ruleIds:[],
                status:1
            },
            data:[]
        }
    }

    service() {
        return RoleService;
    }

    componentDidMount() {
        /* global layui */
        layui.use(['form'], () => {
            let form = layui.form;
            this.server.getService(RuleService).getAll((data) => {
                this.setState({
                    data:data
                }, () => {
                    form.render();
                })
            })
        })
    }

    render() {
        return (
            <div className="layui-form" style={{padding: '20px 30px 0 0'}}>
                <div className="layui-form-item">
                    <label className="layui-form-label">角色</label>
                    <div className="layui-input-inline">
                        <input type="text" name="name" placeholder="角色名称" onChange={e => this.onChangeModel(e)} value={this.state.model.name} className="layui-input" />
                    </div>
                </div>
                <div className="layui-form-item">
                    <label className="layui-form-label">权限范围</label>
                    <div className="layui-input-block">
                        <ul>
                            {
                                this.state.data.map((rule, index) => {
                                    return (
                                        <li key={index} style={{width: '100%',height: 'auto', display: 'block', marginBottom: '20px'}}>
                                            <div className={style.tip}><p>页面路径:{rule['view_path']},<p>接口路径:{rule['api_view']},</p></p>描述:{rule['desc']}</div>
                                            <input type="checkbox" name="ruleIds[]" title={rule['name']}
                                                   value={rule['id']} lay-filter="rules" />
                                            {
                                                rule['children'] && rule['children'].length ?
                                                    (
                                                        <div style={{marginLeft: '20px'}}>
                                                            {
                                                                rule['children'].map((son, ind) => {
                                                                    return (
                                                                        <div style={{marginLeft:'28px'}}>
                                                                            <div key={ind} className={style.tip}><p>页面路径:{son['view_path']},</p><p>接口路径:{son['api_view']},</p><p>描述:{son['desc']}</p></div>
                                                                            <input type="checkbox" name="ruleIds[]" title={son['name']}
                                                                                    className="son" value={son['id']} lay-filter="rules" />
                                                                            {
                                                                                son['children'] ?
                                                                                    (
                                                                                        <div style={{display: 'inline'}}>
                                                                                            {
                                                                                                son['children'].map((v, i) => {
                                                                                                    return (
                                                                                                        <Fragment>
                                                                                                            <div key={i} className={style.tip}><p>页面路径:{v['view_path']},<p>接口路径:{v['api_view']},</p></p>描述:{v['desc']}</div>
                                                                                                            <input type="checkbox" name="ruleIds[]"
                                                                                                                   title={v['name']} className="son"
                                                                                                                   value={v['id']} />
                                                                                                        </Fragment>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                    :''
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                    :''
                                            }
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className="layui-form-item">
                    <label className="layui-form-label">状态</label>
                    <div className="layui-input-inline">
                        <select name="status" value={this.state.model.status}>
                            {
                                this.server.states.map((val, index) => {
                                    return (
                                        <option key={index} value={index}>{val}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="layui-form-item">
                    <label className="layui-form-label">具体描述</label>
                    <div className="layui-input-block">
                        <textarea type="text" name="desc" autoComplete="off" className="layui-textarea" value={this.state.model.desc}></textarea>
                    </div>
                </div>
                <div className="layui-form-item layui-hide">
                    <button className="layui-btn" lay-submit={""} lay-filter="LAY-user-front-submit"
                            id="LAY-user-front-submit">提交
                    </button>
                </div>
            </div>
        );
    }
}

export default RoleAdd;