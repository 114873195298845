import View from "../../../../../vendor/framework/view";
import SeoListLayout from "../layout";

export default class SeoList extends View
{
    render() {
        return (
            <SeoListLayout />
        )
    }
}
