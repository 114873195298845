import Base from "../../../../base";
import style from './index.module.css';
import AdminService from "../../../../../services/admin";

class AdminInformationDetail extends Base
{
    boot() {
        this.state = {
            model:{

            }
        }
    }

    service() {
        return AdminService;
    }

    componentDidMount() {
        /* global layui */
        layui.use(['form'], () => {

        })
    }

    render() {
        return (
            <div className="layui-fluid">
                <div className="layui-card layui-form">
                    <form>
                        <div lay-filter="layuiadmin-form-useradmin" style={{padding: '0px'}}>
                            <div className={style["order-box"]}>
                                <h5>用户信息</h5>
                                <div className={style["form-item"]}>
                                    <label className={style["layui-form-label"]}>用户名:</label>
                                    <div className="layui-input-inline">
                                        <div className={style["order-item"]}>
                                            <span>{this.state.model.name}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={style["form-item"]}>
                                    <label className={style["layui-form-label"]}>手机号:</label>
                                    <div className="layui-input-inline">
                                        <div className={style["order-item"]}>
                                            <span>{this.state.model.mobile}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={style["form-item"]}>
                                    <label className={style["layui-form-label"]}>角色:</label>
                                    <div className="layui-input-inline">
                                        <div className={style["order-item"]}>
                                            <span>{this.state.model.roleName}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className={style["order-box"]}>
                                <div className={style["form-item"]}>
                                    <div className="layui-input-inline">
                                        <div className={style["order-item"]}>
                                            <span> <button className="layui-btn layuiadmin-btn-useradmin" type={"button"} onClick={e => this.modifyPassword()}>修改密码</button></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    modifyPassword() {
        let self = this;
        /* global layer */
        layer.open({
            content:'/admin/admin/password/edit',
            type:2,
            title:'修改密码',
            btn:['确定', '取消'],
            area:['500px', '400px'],
            yes(index, layero) {
                var iframeWindow = window['layui-layer-iframe'+ index]
                    ,submitID = 'LAY-user-front-submit'
                    ,submit = layero.find('iframe').contents().find('#'+ submitID);

                //监听提交
                iframeWindow.layui.form.on('submit('+ submitID +')', function(data){
                    var field = data.field; //获取提交的字段
                    self.server.modifyPassword(field, () => {
                        layer.msg('修改成功!');
                        layer.close(index);
                    }, (msg) => {
                        layer.msg(msg);
                    })
                })
                submit.trigger('click');
            }
        })
    }
}
export default AdminInformationDetail;
