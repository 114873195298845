import ListLayout from "../../../layout/list";
import RoleService from "../../../../services/admin/role";

class RoleListLayout extends ListLayout
{
    fieldsInit() {
        return {
            id(val) {
                var inputName = this.props.inputName || 'data[]';
                return {
                    title:this.list.checkMany ? (<span><input type="checkbox" lay-skin="primary" lay-filter="checkAll" />{"ID"}</span>) : 'ID',
                    value:<td>{this.list.canCheck ? <input type="checkbox" name={inputName} lay-skin="primary" lay-filter="ids" className="ids" value={JSON.stringify(val) }/> :
                        <input type="hidden" name={inputName} value={val.id}/>}{val.id}</td>
                }
            },
            name:'名称',
            descDup:'描述',
            stateDup:'状态',
            createdAt:'添加时间'
        }
    }

    oprationInit(model) {
        return {
            title:'操作',
            values:{
                edit() {
                    return (
                        <button onClick={e => this.edit(model)} className="layui-btn layui-btn-normal layui-btn-xs">
                            <i className="layui-icon layui-icon-edit"></i><span>编辑</span>
                        </button>
                    )
                },
                delete() {
                    return (
                        <button onClick={e => this.delete(model)} className={"layui-btn layui-btn-danger layui-btn-xs" + (! model.canDelete() ? ' layui-btn-disabled' : '')}>
                            <i className="layui-icon layui-icon-delete"></i><span>删除</span>
                        </button>
                    )
                },
            }
        }
    }

    btnExtentions() {
        return {
            add() {
                return (
                    <button className="layui-btn" type='button' onClick={e => this.add()}>添加</button>
                )
            }
        }
    }

    service() {
        return RoleService;
    }

    componentDidMount() {
        /* global layui,layer */
        layui.use(['form'], () => {

        })
    }

    add() {
        let self = this;
        layer.open({
            title:'添加角色',
            type:2,
            content:'/admin/role/add',
            area:['1100px', '90%'],
            btn:['确定', '取消'],
            yes(index, layero) {
                var iframeWindow = window['layui-layer-iframe'+ index]
                    ,submitID = 'LAY-user-front-submit'
                    // eslint-disable-next-line
                    ,submit = layero.find('iframe').contents().find('#'+ submitID);

                //监听提交
                iframeWindow.layui.form.on('submit('+ submitID +')', function(data){
                    var field = data.field; //获取提交的字段
                    self.server.addOrEdit(field, () => {
                        layer.msg('添加成功!');
                        layer.close(index);
                        self.loadData();
                    }, (msg) => {
                        layer.msg(msg);
                    })
                })
                submit.trigger('click');
            }
        })
    }

    edit(val) {
        let self = this;
        layer.open({
            title:'修改角色 '+val.name,
            type:2,
            content:'/admin/role/edit/'+val.id,
            area:['1100px', '90%'],
            btn:['确定', '取消'],
            yes(index, layero) {
                var iframeWindow = window['layui-layer-iframe'+ index]
                    ,submitID = 'LAY-user-front-submit'
                    // eslint-disable-next-line
                    ,submit = layero.find('iframe').contents().find('#'+ submitID);

                //监听提交
                iframeWindow.layui.form.on('submit('+ submitID +')', function(data){
                    var field = data.field; //获取提交的字段
                    field.id = val.id;
                    self.server.addOrEdit(field, () => {
                        layer.msg('修改成功!');
                        layer.close(index);
                        self.loadData();
                    }, (msg) => {
                        layer.msg(msg);
                    })
                })
                submit.trigger('click');
            }
        })
    }

    delete(model) {
        if (model.canDelete()) {
            layer.confirm('您真的要删除 ' + model.name + ' 这个角色吗?', () => {
                this.server.delete(model.id, () => {
                    layer.msg('删除成功!');
                    this.pageReload();
                }, (msg) => {
                    layer.msg(msg);
                })
            })
        }
    }
}
export default RoleListLayout;