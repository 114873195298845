import Base from "../../base";
import Form from "../../layout/form";
import NewsService from "../../../services/news";

class NewsAdd extends Base
{
    boot() {
        this.state = {
            model:{
                title:'',
                content:'',
                desc:'',
                sort:0,
                state:1
            }
        }
    }

    service() {
        return NewsService;
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={
                    {
                        title: {name: '标题'},
                        keywords:{name:'关键字', type: 'textarea'},
                        desc: {name: '简介', type: 'textarea'},
                        content: {name: '内容', type: 'editor'},
                        sort: {name: '排序', type: 'number'},
                        state: {name: '状态', type: 'select', data: this.server.states}
                    }
                }
            />
        );
    }
}
export default NewsAdd;
