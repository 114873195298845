import View from "../../../../../vendor/framework/view";
import Table from "../../layout/table";
import SeoService from "../../../services/seo";
import $ from "jquery";

class SeoListLayout extends View
{
    boot() {
        this.state = {
            types:[]
        }
    }

    componentDidMount() {
        /* global layer */
        this.server.getTypes(res => {
            this.setState({
                types:res.data
            })
        })
    }

    service() {
        return SeoService;
    }

    render() {
        return (
            <Table
                service={SeoService}
                fieldsInit={{
                    id:'ID',
                    name:'页面',
                    titleDup:'标题',
                    keywordsDup:'关键字',
                    descriptionDup:'描述',
                    createdAt:'添加时间'
                }}
                oprationInit={{
                    edit:{name:'修改', icon:['edit'], click:this.edit.bind(this)},
                    delete:{name: '删除', icon:['delete', 'danger'], click:this.delete.bind(this)}
                }}
                searchInit={{
                    keywords:{name:'关键字', enter:true},
                    type:{name:'页面', type:'select',data:this.state.types, option:['id', 'name'], default:-1}
                }}
                btnInit={{
                    add:{name:'添加', click: this.add.bind(this)}
                }}
            />
        )
    }

    add(table) {
        let _this = this,
            layIndex = layer.open({
                title:'添加新闻',
                type:2,
                content:'/admin/seo/add',
                area:['80%', '90%'],
                btn:['确定', '取消'],
                maxmin:true,
                min() {
                    $('#layui-layer-shade'+layIndex).hide();
                },
                max() {
                    $('#layui-layer-shade'+layIndex).show();
                },
                restore() {
                    $('#layui-layer-shade'+layIndex).show();
                },
                yes(index, layero) {
                    var iframeWindow = window['layui-layer-iframe'+ index]
                        ,submitID = 'LAY-user-front-submit'
                        // eslint-disable-next-line
                        ,submit = layero.find('iframe').contents().find('#'+ submitID);

                    //监听提交
                    iframeWindow.layui.form.on('submit('+ submitID +')', function(data){
                        var field = data.field; //获取提交的字段
                        _this.server.addOrEdit(field, () => {
                            layer.msg('添加成功!');
                            layer.close(index);
                            table.loadData();
                        }, (msg) => {
                            layer.msg(msg);
                        })
                    })
                    submit.trigger('click');
                }
            })
    }

    edit(model, table) {
        let _this = this,
            layIndex = layer.open({
                title:'修改 ' + model.title,
                type:2,
                content:'/admin/seo/edit/'+model.id,
                area:['80%', '90%'],
                btn:['确定', '取消'],
                maxmin:true,
                min() {
                    $('#layui-layer-shade'+layIndex).hide();
                },
                max() {
                    $('#layui-layer-shade'+layIndex).show();
                },
                restore() {
                    $('#layui-layer-shade'+layIndex).show();
                },
                yes(index, layero) {
                    var iframeWindow = window['layui-layer-iframe'+ index]
                        ,submitID = 'LAY-user-front-submit'
                        // eslint-disable-next-line
                        ,submit = layero.find('iframe').contents().find('#'+ submitID);

                    //监听提交
                    iframeWindow.layui.form.on('submit('+ submitID +')', function(data){
                        var field = data.field; //获取提交的字段
                        field.id = model.id;
                        _this.server.addOrEdit(field, () => {
                            layer.msg('修改成功!');
                            layer.close(index);
                            table.pageReload();
                        }, (msg) => {
                            layer.msg(msg);
                        })
                    })
                    submit.trigger('click');
                }
            })
    }

    delete(model, table) {
        layer.confirm('您真的要删除 '+model.title+' 吗?', () => {
            this.server.delete(model, () => {
                layer.msg('删除成功!');
                table.pageReload();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }
}
export default SeoListLayout;
