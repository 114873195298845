import Base from "../../base";
import NewsListLayout from "../layout";

class NewsList extends Base
{
    render() {
        return (
            <NewsListLayout />
        );
    }
}
export default NewsList;
