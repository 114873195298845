import Base from "../../../base";
import Form from "../../../layout/form";
import AboutTypeService from "../../../../services/about/type";

class AboutTypeEdit extends Base
{
    boot() {
        this.state = {
            model:{
                name:'',
                sort: 0,
                state: 1
            }
        }
    }

    service() {
        return AboutTypeService;
    }

    componentDidMount() {
        this.server.getDetail(this.getPropsParams('id'), (res) => {
            this.setModel(res.data);
        })
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={
                    {
                        name: '类型名称',
                        sort: {name: '排序', type: 'number'},
                        state: {name: '状态', type: 'radio', data: this.server.states}
                    }
                }
            />
        );
    }
}
export default AboutTypeEdit;
