import CooperationListLayout from "../layout";
import Base from "../../../base";

class CooperationList extends Base
{
    render() {
        return (
            <CooperationListLayout />
        );
    }
}
export default CooperationList;
