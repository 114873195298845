import BaseService from "../base";

class UserService extends BaseService
{
    requestUrls = {

    }

    getList(conf) {

    }
}
export default UserService;