import View from "../../../../../vendor/framework/view";
import Table from "../../layout/table";
import OprationService from "../../../services/opration";
import OprationTypeService from "../../../services/opration/type";

class OprationListLayout extends View
{
    boot() {
        this.state = {
            types:[]
        }

        this.status = [
            {key:-1, name: '--全部--'}
        ]
        for(var k in this.server.states) {
            this.status.push({key:Number(k), name:this.server.states[k]});
        }
    }

    componentDidMount() {
        /* global layer */
        this.server.getService(OprationTypeService).getList((res) => {
            let all = [{id:-1, name:'--全部--'}];
            this.setState({
                types:all.concat(res.data)
            })
        })
    }

    service() {
        return OprationService;
    }

    render() {
        return (
            <Table
                service={OprationService}
                searchInit={{
                    keywords:{name:'关键字', enter:true},
                    type:{name:'类型', type:'select', data: this.state.types, value:0, option:['id', 'name']},
                    state:{name:'状态', type:'select', data: this.status, value: -1, option:['key', 'name']}
                }}
                fieldsInit={{
                    id:'ID',
                    typeName:'类型',
                    name:'名称',
                    link:'链接',
                    sort:{name:'排序', type: 'input', change:this.server.changeSort.bind(this.server)},
                    state:{name:'状态', type:'switch', checked:1, text:'开启|禁用', change:this.server.changeState.bind(this.server)},
                    createdAt:'添加时间',
                    updatedAt:'更新时间'
                }}
                oprationInit={{
                    edit:{name:'编辑', icon: ['edit'], click: this.edit.bind(this)},
                    delete:{name: '删除', icon:['delete', 'danger'], click:this.delete.bind(this)}
                }}
                btnInit={{
                    add:{name:'添加', click: this.add.bind(this)}
                }}
            />
        );
    }

    add(table) {
        let _this = this;
        layer.open({
            title:'添加',
            type:2,
            content:'/admin/opration/add',
            area:['800px', '90%'],
            btn:['确定', '取消'],
            yes(index, layero) {
                var iframeWindow = window['layui-layer-iframe'+ index]
                    ,submitID = 'LAY-user-front-submit'
                    // eslint-disable-next-line
                    ,submit = layero.find('iframe').contents().find('#'+ submitID);

                //监听提交
                iframeWindow.layui.form.on('submit('+ submitID +')', function(data){
                    var field = data.field; //获取提交的字段
                    _this.server.addOrEdit(field, () => {
                        layer.msg('添加成功!');
                        layer.close(index);
                        table.loadData();
                    }, (msg) => {
                        layer.msg(msg);
                    })
                })
                submit.trigger('click');
            }
        })
    }

    edit(model, table) {
        let _this = this;
        layer.open({
            title:'修改'+model.id,
            type:2,
            content:'/admin/opration/edit/'+model.id,
            area:['800px', '90%'],
            btn:['确定', '取消'],
            yes(index, layero) {
                var iframeWindow = window['layui-layer-iframe'+ index]
                    ,submitID = 'LAY-user-front-submit'
                    // eslint-disable-next-line
                    ,submit = layero.find('iframe').contents().find('#'+ submitID);

                //监听提交
                iframeWindow.layui.form.on('submit('+ submitID +')', function(data){
                    var field = data.field; //获取提交的字段
                    field.id = model.id;
                    _this.server.addOrEdit(field, () => {
                        layer.msg('修改成功!');
                        layer.close(index);
                        table.pageReload();
                    }, (msg) => {
                        layer.msg(msg);
                    })
                })
                submit.trigger('click');
            }
        })
    }

    delete(model, table) {
        layer.confirm('您真的要删除 '+model.name+' 吗?', () => {
            this.server.delete(model, () => {
                layer.msg('删除成功!');
                table.pageReload();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }
}
export default OprationListLayout;
