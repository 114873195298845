import Base from "../../base";
import OprationListLayout from "../layout";

class OprationList extends Base
{
    render() {
        return (
            <OprationListLayout />
        );
    }
}
export default OprationList;
