import Base from "../../base";
import AboutListLayout from "../layout";

class AboutList extends Base
{
    render() {
        return (
            <AboutListLayout />
        );
    }
}
export default AboutList;
