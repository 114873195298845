import Base from "../../../base";
import AdminListLayout from "../layout";

class AdminList extends Base
{
    render() {
        return (
            <AdminListLayout
                paging={true}
                layForm={true}
                searchShow={true}
                btnShow={true}
            />
        );
    }
}

export default AdminList;