import View from "../../../../vendor/framework/view";

class Error extends View
{
    boot() {
        this.state = {

        }
    }

    render() {
        return (
            <div className="layui-fluid">
                <div className="layadmin-tips">
                    <i className="layui-icon" style={{fontSize:'280px'}} face>&#xe664;</i>

                    <div className="layui-text" style={{fontSize: '20px'}}>
                        您好像没有权限访问
                    </div>

                </div>
            </div>
        );
    }
}
export default Error;