import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";
import md5 from 'js-md5'

class AdminService extends BaseService
{
   requestUrls = {
       informationUrl:'/getInfo',
       modifyPasswordUrl:'api/updatePw',
       url:'/list',
       addOrEditUrl:'/addOrEdit',
       detailUrl:'/detail',
       deleteUrl:'/delete'
   }

   states = ['停用', '正常'];

   appends = ['stateDup', 'canEdit', 'canEditUsername'];

   leaders = ['admin'];

   addOrEdit(model, suc, err) {
       var validator = new Validator(),
           rules = [
               ['username', 'required', '请填写账号'],
               ['roleId', 'required|gt:0', '请选择角色|请选择角色']
           ];

       if (! model.id) {
           rules.push(
               ['password', 'required|confirmed', '请填写密码|确认密码不正确']
           )
       } else {
           rules.push(
               ['password', 'confirmed', '确认密码不正确']
           )
       }
       if (! validator.validate(rules, model)) {
           return err(validator.getError());
       }

       this.request({
           url:this.requestUrls.addOrEditUrl,
           data:model,
           success:suc,
           error:err
       })
   }

   getDetail(id, suc) {
       this.request({
           url:this.requestUrls.detailUrl,
           data:{id:id},
           success:suc
       })
   }

   delete(id, suc, err) {
       this.request({
           url:this.requestUrls.deleteUrl,
           data:{id:id},
           success:suc
       })
   }

   getInforamation() {
       let self = this;
       self.request({
           url:this.requestUrls.informationUrl,
           success(msg, data) {
            self.page.setState({
                model:data
            })
           }
       })
   }

    modifyPassword(field, suc, err) {
       var validator = new Validator(),
           rules = [
               ['oldPassword', 'required', '原密码不能为空'],
               ['newPassword', 'required|confirmed', '新密码不能为空|重复新密码不正确'],
           ];

       if (! validator.validate(rules, field)) {
           return err(validator.getError());
       }

       this.request({
            url:this.requestUrls.modifyPasswordUrl,
            data:{oldPassword:md5(field.oldPassword),newPassword:md5(field.newPassword)},
            success:suc,
            error:err
       })
    }

    methods() {
       return {
           canDelete() {
               return this.username !== 'admin';
           }
       }
    }

    getStateDupAttribute() {
       return this.states[this.attributes['status']];
    }

    getLastLoginAtAttribute(val) {
        return val ? this.app.formatDate(val, '-') : '';
    }

    getCanEditAttribute(val, model) {
       return this.leaders.indexOf(this.auth.getUser('username')) > -1 || this.leaders.indexOf(model.username) < 0;
    }

    getCanEditUsernameAttribute(val, model) {
       return this.leaders.indexOf(model.username) === -1;
    }
}

export default AdminService;
