import View from "../../../../vendor/framework/view";
import Image from "./image";
import style from "./slides.module.css"
import Editor from "./editor";

class Form extends View {
    constructor(props) {
        super(props);
        this.state = {
            model: props.model || {},
            fields: props.fields || {}
        }

        this.defaultType = 'text';
    }

    componentDidMount() {
        /* global layui */
        layui.use(['form'], () => {
            let form = layui.form,
                _this = this,
                types = ['radio', 'select'];

            for(let k in this.state.fields) {
                let item = this.state.fields[k];
                if (Boolean(item) && typeof item === 'object' && types.indexOf(item.type) > -1) {
                    form.on(item.type+'(field'+k+')', (data) => {
                        _this.setModel(k, data.value, () => {
                            form.render(item.type);
                        });
                    })
                }
            }
        })
    }

    componentWillReceiveProps(props) {
        let obj = {};
        if (props.model) {
            obj.model = props.model;
        }
        if (props.fields) {
            obj.fields = props.fields;
        }

        this.setState(obj, () => {
            layui.form && layui.form.render();
        })
    }

    tip(tip) {
        if (tip) {
            return (
                <div className="layui-input-inline">
                    <span className={style.exp}>* {tip}</span>
                </div>
            )
        }
    }

    text(name, item, key) {
        return (
            <div className="layui-form-item" key={key}>
                <label className="layui-form-label">{typeof item === 'string' ? item : item.name}</label>
                <div className="layui-input-inline">
                    <input type="text" name={name} autoComplete="off"
                           className="layui-input" onChange={e => this.onChangeModel(e)}
                           value={this.state.model[name]}/>
                </div>
                {
                    this.tip(item.tip)
                }
            </div>
        )
    }

    number(name, item, key) {
        return (
            <div className="layui-form-item" key={key}>
                <label className="layui-form-label">{item.name}</label>
                <div className="layui-input-inline">
                    <input type="number" name={name} autoComplete="off"
                           className="layui-input" onChange={e => this.onChangeModel(e)}
                           value={this.state.model[name]}/>
                </div>
                {
                    this.tip(item.tip)
                }
            </div>
        )
    }

    textarea(name, item, key) {
        return (
            <div className="layui-form-item" key={key}>
                <label className="layui-form-label">{item.name}</label>
                <div className="layui-input-inline">
                    <textarea name={name} className="layui-textarea" onChange={e => this.onChangeModel(e)}
                              value={this.state.model[name]}/>
                </div>
                {
                    this.tip(item.tip)
                }
            </div>
        )
    }

    image(name, item, key) {
        return (
            <div className="layui-form-item" key={key}>
                <label className="layui-form-label">{item.name}</label>
                <div className="layui-input-block">
                    <Image src={this.state.model[name]}
                           canDel={item.canDel !== undefined ? item.canDel : true}
                           inputName={name}
                           handle={this.uploadShowPic.bind(this)}
                           max={item.max || '512K'}
                           tipExtentions={() => {
                               if (item.tip) {
                                   return (
                                       <div style={{
                                           height: '38px',
                                           lineHeight: '38px',
                                           marginLeft: '10px',
                                           float: 'left',
                                           color: '#999'
                                       }}>{item.tip}</div>
                                   )
                               }
                           }}
                    />
                </div>
            </div>
        )
    }

    selectFromArray(item) {
        return item.data.map((val, index) => {
            if (item.option) {
                return (
                    <option key={index} value={val[item.option[0]]}>{val[item.option[1]]}</option>
                )
            }
            return (
                <option key={index} value={index}>{val}</option>
            )
        })
    }

    selectFromObject(item) {
        return item.data.map((key, index) => {
            return (
                <option key={index} value={key}>{item.data[key]}</option>
            )
        })
    }

    select(name, item, key) {
        return (
            <div className="layui-form-item" key={key}>
                <label className="layui-form-label">{item.name}</label>
                <div className="layui-input-inline">
                    <select name={name} lay-filter={'field'+name} value={this.state.model[name]}>
                        {
                            item.data instanceof Array ? this.selectFromArray(item) : this.selectFromObject(item)
                        }
                    </select>
                </div>
            </div>
        )
    }

    editor(name, item, key) {
        return (
            <div className="layui-form-item" key={key}>
                <label className="layui-form-label">{item.name}</label>
                <div className="layui-input-block">
                    <Editor
                        contentChange={this.editorContentChange.bind(this)}
                        content={this.state.model[name]}
                    />
                </div>
            </div>
        )
    }

    radioFromArray(name, item) {
        return item.data.map((val, index) => {
            if (item.option) {
                return (
                    <input key={index} type="radio" name={name} lay-filter={'field'+name} checked={val[item.option[0]] === this.state.model[item.option[0]]} value={val[item.option[0]]} title={val[item.option[1]]} />
                )
            }
            return (
                <input key={index} type="radio" name={name} lay-filter={'field'+name} checked={index === Number(this.state.model[name])} value={index} title={val} />
            )
        })
    }

    radioFromObject(name, item) {
        return Object.keys(item.data).map((key, index) => {
            return (
                <input key={index} type="radio" name={name} checked={key === this.state.model[name]} lay-filter={'field'+name} value={key} title={item.data[key]} />
            )
        })
    }

    radio(name, item, key) {
        return (
            <div className="layui-form-item" key={key}>
                <label className="layui-form-label">{item.name}</label>
                <div className="layui-input-inline">
                    {
                        item.data instanceof Array ? this.radioFromArray(name, item) : this.radioFromObject(name, item)
                    }
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="layui-form" style={{padding: '20px 30px 0 0'}}>
                {
                    Object.keys(this.state.fields).map((key, index) => {
                        var item = this.state.fields[key];
                        if (typeof item === 'string') {
                            return this.text(key, item, index);
                        }
                        var type = item.type || this.defaultType;
                        return this[type](key, item, index);
                    })
                }
                <div className="layui-form-item layui-hide">
                    <input type="button" lay-submit={""} lay-filter="LAY-user-front-submit" id="LAY-user-front-submit"
                           value="确认"/>
                </div>
            </div>
        );
    }

    uploadShowPic(image, name) {
        this.setModel(name, image);
    }

    editorContentChange(content, name) {
        this.state.model[name] = content;
    }
}

export default Form;
