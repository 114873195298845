import View from "../../../../../../vendor/framework/view";
import $ from 'jquery';
import style from './index.module.css';

class RuleIcon extends View
{
    boot() {
        this.state = {
            model:{
                keywords:'',
                icon:{

                }
            },
            html:''
        }
        this.requireCss([
            '/iconfont/demo.css',
            'https://g.alicdn.com/thx/cube/1.3.2/cube.min.css'
        ])
    }

    componentDidMount() {
        let self = this;
        /* global layui */
        layui.use(['form'], () => {
            $.ajax({
                url:'/iconfont/demo_index.html',
                type:'get',
                success(res) {
                    let html = $(res).find('.dib-box').eq(2).html();
                    html = html.replace(/class="dib"/g, 'class="dib '+style.dib+'"');
                    $('#iconBox').html(html);
                    self.setState({
                        html:html
                    })
                }
            })

            $(document).on('click', '.'+style.dib, function() {
                if (! $(this).hasClass(style.dibSelected)) {
                    $(this).addClass(style.dibSelected);
                    if ($(this).siblings().hasClass(style.dibSelected)) {
                        $(this).siblings().removeClass(style.dibSelected);
                    }
                }
                self.setModel('icon', {
                    name:$(this).find('.name').text().replace(/\s+/g,""),
                    className:$(this).find('.code-name').text().replace(/\./g, '').replace(/\s+/g,"")
                });
            })
        })
    }

    render() {
        return (
            <div className="layui-form" style={{padding: '20px 0 0 20px'}}>
                <div className="layui-form-item">
                    <label className="layui-form-label">搜索</label>
                    <div className="layui-inline">
                        <input type="text" name="keywords" onChange={e => this.onChangeModel(e)} autoComplete="off" className={"layui-input ruleName"}/>
                    </div>
                </div>
                <div id={"iconBox"}>

                </div>
                <div className="layui-form-item">
                    <input type="hidden" name={"icon"} value={JSON.stringify(this.state.model.icon)}/>
                </div>
                <div className="layui-form-item layui-hide">
                    <input type="button" lay-submit={""} lay-filter="LAY-user-front-submit" id="LAY-user-front-submit"
                           value="确认" />
                </div>
            </div>
        );
    }

    onChangeModel(e) {
        let shows = '',
            keywords = e.target.value;
        if (keywords) {
            $(document).find('.dib').each(function() {
                let name = $(this).find('.name').text(),
                    className = $(this).find('.code-name').text();
                if (name.indexOf(keywords) > -1 || className.indexOf(keywords) > -1) {
                    shows += $('<div>').append($(this).clone()).html();
                }
                // else {
                //     hides += $('<div>').append($(this).clone()).html();
                // }
            })
        } else {
            shows = this.state.html;
        }

        $('#iconBox').html(shows);
    }
}
export default RuleIcon;
