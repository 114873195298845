import Base from "../../../base";
import BannerService from "../../../../services/running/banner";
import Form from "../../../layout/form";

class BannerAdd extends Base
{
    boot() {
        this.state = {
            model:{
                name:'',
                image:'',
                link:'',
                sort:0,
                state:1
            }
        }
    }

    service() {
        return BannerService;
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={{
                    name: '名称',
                    image:{name:'图片', type:'image'},
                    link:{name:'链接', type:'textarea'},
                    sort:'排序',
                    state:{name:'状态', type: 'radio', data: this.server.states}
                }}
            />
        );
    }
}
export default BannerAdd;
