import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";

class AboutTypeService extends BaseService
{
    requestUrls = {
        url:'/about/type/list',
        addOrEditUrl:'/about/type/addOrEdit',
        detailUrl:'/about/type/detail',
        deleteUrl:'/about/type/delete',
        updateStateUrl:'/about/type/updateState',
        updateSortUrl:'/about/type/updateSort',
    }

    states = ['禁用', '启用'];

    getList(suc) {
        this.post(
            this.requestUrls.url,
            {},
            suc
        )
    }

    addOrEdit(model, suc, err) {
        let validator = new Validator(),
            rules = [
                ['name', 'required', '请填写类型名称'],
            ];
        if (! validator.validate(rules, model)) {
            return err(validator.getError());
        }

        this.post(
            this.requestUrls.addOrEditUrl,
            model,
            suc,
            err
        )
    }

    changeState(data, suc, err) {
        let model = {
            id:data.id,
            state:Number(data.state) === 0 ? 1 : 0
        }
        this.post(
            this.requestUrls.updateStateUrl,
            model,
            suc,
            err
        )
    }

    changeSort(data, suc, err) {
        let model = {
            id:data.id,
            sort:data.sort
        }
        this.post(
            this.requestUrls.updateSortUrl,
            model,
            suc,
            err
        )
    }

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detailUrl,
            {id:id},
            suc
        )
    }

    delete(model, suc, err) {
        this.post(
            this.requestUrls.deleteUrl,
            {id:model.id},
            suc,
            err
        )
    }
}
export default AboutTypeService;
