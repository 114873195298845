import Base from "../../../base";
import OprationTypeListLayout from "../layout";

class OprationTypeList extends Base
{
    render() {
        return (
            <OprationTypeListLayout />
        );
    }
}
export default OprationTypeList;
