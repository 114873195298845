import Base from "../../../base";
import Form from "../../../layout/form";
import OprationTypeService from "../../../../services/opration/type";

class OprationTypeEdit extends Base
{
    boot() {
        this.state = {
            model:{
                name:'',
                sort:0,
                state:1
            }
        }
    }

    service() {
        return OprationTypeService;
    }

    componentDidMount() {
        this.server.getDetail(this.getPropsParams('id'), (res) => {
            this.setModel(res.data);
        })
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={
                    {
                        name: '名称',
                        sort: {name: '排序', type: 'number'},
                        state: {name: '状态', type: 'select', data: this.server.states}
                    }
                }
            />
        );
    }
}
export default OprationTypeEdit;
