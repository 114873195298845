import NewsService from "../../../services/news";
import View from "../../../../../vendor/framework/view";
import Table from "../../layout/table";
import $ from "jquery";

class NewsListLayout extends View
{
    stateInit() {
        return {
            search:{
                keywords:'',
                state:-1,
                startTime:'',
                endTime:''
            }
        }
    }

    render() {
        return (
            <Table
                service={NewsService}
                searchInit={{
                    keywords:{name:'关键字', enter:true},
                    state:{name:'状态', value:'-1',type: 'select', data: [{key:'-1',name:'--全部--'},{key:1,name:'开启'}, {key:0, name:'禁用'}], option:['key', 'name']},
                    startTime:{name:'添加时间', placeholder:'开始时间', type: 'date', format:'datetime', position:0},
                    endTime:{placeholder: '结束时间', type:'date',  format:'datetime', position:1}
                }}
                fieldsInit={{
                    id:'ID',
                    title:'标题',
                    desc:'简介',
                    sort:{name:'排序', type:'input', change:this.server.changeSort.bind(this.server)},
                    state:{name:'状态', type:'switch', checked:1, text:'开启|禁用',change:this.server.changeState.bind(this.server)},
                    createdAt:'添加时间',
                    updatedAt:'修改时间'
                }}
                oprationInit={{
                    edit:{name:'修改', icon:['edit'], click:this.edit.bind(this)},
                    delete:{name: '删除', icon:['delete', 'danger'], click:this.delete.bind(this)}
                }}
                btnInit={{
                    add:{name:'添加', click: this.add.bind(this)}
                }}
            />
        );
    }

    service() {
        return NewsService;
    }

    componentDidMount() {
        /* global layer */
    }

    add(table) {
        let _this = this,
        layIndex = layer.open({
            title:'添加新闻',
            type:2,
            content:'/admin/news/add',
            area:['80%', '90%'],
            btn:['确定', '取消'],
            maxmin:true,
            min() {
                $('#layui-layer-shade'+layIndex).hide();
            },
            max() {
                $('#layui-layer-shade'+layIndex).show();
            },
            restore() {
                $('#layui-layer-shade'+layIndex).show();
            },
            yes(index, layero) {
                var iframeWindow = window['layui-layer-iframe'+ index]
                    ,submitID = 'LAY-user-front-submit'
                    // eslint-disable-next-line
                    ,submit = layero.find('iframe').contents().find('#'+ submitID);

                //监听提交
                iframeWindow.layui.form.on('submit('+ submitID +')', function(data){
                    var field = data.field; //获取提交的字段
                    _this.server.addOrEdit(field, () => {
                        layer.msg('添加成功!');
                        layer.close(index);
                        table.loadData();
                    }, (msg) => {
                        layer.msg(msg);
                    })
                })
                submit.trigger('click');
            }
        })
    }

    edit(model, table) {
        let _this = this,
        layIndex = layer.open({
            title:'修改 ' + model.title,
            type:2,
            content:'/admin/news/edit/'+model.id,
            area:['80%', '90%'],
            btn:['确定', '取消'],
            maxmin:true,
            min() {
                $('#layui-layer-shade'+layIndex).hide();
            },
            max() {
                $('#layui-layer-shade'+layIndex).show();
            },
            restore() {
                $('#layui-layer-shade'+layIndex).show();
            },
            yes(index, layero) {
                var iframeWindow = window['layui-layer-iframe'+ index]
                    ,submitID = 'LAY-user-front-submit'
                    // eslint-disable-next-line
                    ,submit = layero.find('iframe').contents().find('#'+ submitID);

                //监听提交
                iframeWindow.layui.form.on('submit('+ submitID +')', function(data){
                    var field = data.field; //获取提交的字段
                    field.id = model.id;
                    _this.server.addOrEdit(field, () => {
                        layer.msg('修改成功!');
                        layer.close(index);
                        table.pageReload();
                    }, (msg) => {
                        layer.msg(msg);
                    })
                })
                submit.trigger('click');
            }
        })
    }

    delete(model, table) {
        layer.confirm('您真的要删除 '+model.title+' 吗?', () => {
            this.server.delete(model, () => {
                layer.msg('删除成功!');
                table.pageReload();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }
}
export default NewsListLayout;
