import UserListLayout from "../layout/list";
import Base from "../../base";

class UserList extends Base
{
	render() {
		return (
			<UserListLayout searchShow={true}
							paging={true}
							layForm={true}
							oprationFields={['detail', 'setExtensionAgent', 'follow']}
							canCheck={true}
							checkMany={true}
							checkboxInit={true}
							autoload={false}
							list={[{
								id:1,
								name:'Alex'
							}]}
							 />
		);
	}
}

export default UserList;