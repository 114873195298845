import BaseService from "../../base";

class CooperationService extends BaseService
{
    requestUrls = {
        url:'/cooperation/list',
        detailUrl:'/cooperation/detail',
        updateStateUrl:'/cooperation/updateState',
        deleteUrl:'/cooperation/delete'
    }

    states = ['待审核','审核通过','审核拒绝'];

    appends = ['stateDup', 'canAudit'];

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detailUrl,
            {id:id},
            suc
        )
    }

    delete(model, suc, err) {
        this.post(
            this.requestUrls.deleteUrl,
            {id:model.id},
            suc,
            err
        )
    }

    changeState(data, suc, err) {
        this.post(
            this.requestUrls.updateStateUrl,
            data,
            suc,
            err
        )
    }

    getStateDupAttribute() {
        return this.states[this.attributes['state']];
    }

    getCanAuditAttribute() {
        return this.attributes['state'] === 0;
    }
}
export default CooperationService;
