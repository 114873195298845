import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";

class AboutService extends BaseService
{
    requestUrls = {
        url:'/about/list',
        addOrEditUrl:'/about/addOrEdit',
        detailUrl:'/about/detail',
        updateStateUrl:'/about/updateState',
        updateSortUrl:'/about/updateSort',
        deleteUrl:'/about/delete'
    }

    states = ['禁用', '启用'];

    appends = ['contentDup'];

    addOrEdit(model, suc, err) {
        let validator = new Validator(),
            rules = [
                ['content', 'required', '请填写内容'],
            ];
        if (! validator.validate(rules, model)) {
            return err(validator.getError());
        }

        this.post(
            this.requestUrls.addOrEditUrl,
            model,
            suc,
            err
        )
    }

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detailUrl,
            {id:id},
            suc
        )
    }

    delete(model, suc, err) {
        this.post(
            this.requestUrls.deleteUrl,
            {id:model.id},
            suc,
            err
        )
    }

    changeState(data, suc, err) {
        let model = {
            id:data.id,
            state:Number(data.state) === 0 ? 1 : 0
        }
        this.post(
            this.requestUrls.updateStateUrl,
            model,
            suc,
            err
        )
    }

    changeSort(data, suc, err) {
        let model = {
            id:data.id,
            sort:data.sort
        }
        this.post(
            this.requestUrls.updateSortUrl,
            model,
            suc,
            err
        )
    }

    getContentDupAttribute() {
        var content = this.app.delHtmlTag(this.attributes['content']);
        var length = content.length;
        if (! content && this.attributes['content']) {
            if (this.attributes['content'].match(/<img/)) {
                return '--图片内容--';
            }
        }
        return length > 33 ? (content.substr(0, 30) + '...') : content;
    }
}
export default AboutService;
