import View from "../../../../../../vendor/framework/view";
import Table from "../../../layout/table";
import AboutTypeService from "../../../../services/about/type";

class AboutTypeListLayout extends View
{
    boot() {
        this.state = {
            list:[]
        }
    }

    service() {
        return AboutTypeService;
    }

    load() {
        this.server.getList((res) => {
            this.setState({
                list:res.data
            })
        })
    }

    componentDidMount() {
        /* global layer */
        this.load();
    }

    render() {
        return (
            <Table
                autoload={false}
                list={this.state.list}
                btnShow={true}
                fieldsInit={{
                    id:'ID',
                    name:'类型',
                    sort:{name:'排序', type: 'input', change:this.changeSort.bind(this)},
                    state:{name:'状态', type:'switch', checked:1, text:'开启|禁用', change:this.changeState.bind(this)},
                    createdAt:'添加时间',
                    updatedAt:'修改时间'
                }}
                oprationInit={{
                    edit:{name:'修改', icon:'edit', click:this.edit.bind(this)},
                    delete:{name: '删除', icon:['delete', 'danger'], click:this.delete.bind(this)}
                }}
                btnInit={{
                    add:{name:'添加', click:this.add.bind(this)}
                }}
            />
        );
    }

    add() {
        let _this = this;
        layer.open({
            title:'添加类型',
            type:2,
            content:'/admin/about/type/add',
            area:['500px', '300px'],
            btn:['确定', '取消'],
            yes(index, layero) {
                var iframeWindow = window['layui-layer-iframe'+ index]
                    ,submitID = 'LAY-user-front-submit'
                    // eslint-disable-next-line
                    ,submit = layero.find('iframe').contents().find('#'+ submitID);

                //监听提交
                iframeWindow.layui.form.on('submit('+ submitID +')', function(data){
                    var field = data.field; //获取提交的字段
                    _this.server.addOrEdit(field, () => {
                        layer.msg('添加成功!');
                        layer.close(index);
                        _this.load();
                    }, (msg) => {
                        layer.msg(msg);
                    })
                })
                submit.trigger('click');
            }
        })
    }

    edit(model) {
        let _this = this;
        layer.open({
            title:'修改类型 '+model.name,
            type:2,
            content:'/admin/about/type/edit/'+model.id,
            area:['500px', '300px'],
            btn:['确定', '取消'],
            yes(index, layero) {
                var iframeWindow = window['layui-layer-iframe'+ index]
                    ,submitID = 'LAY-user-front-submit'
                    // eslint-disable-next-line
                    ,submit = layero.find('iframe').contents().find('#'+ submitID);

                //监听提交
                iframeWindow.layui.form.on('submit('+ submitID +')', function(data){
                    var field = data.field; //获取提交的字段
                    field.id = model.id;
                    _this.server.addOrEdit(field, () => {
                        layer.msg('修改成功!');
                        layer.close(index);
                        _this.load();
                    }, (msg) => {
                        layer.msg(msg);
                    })
                })
                submit.trigger('click');
            }
        })
    }

    delete(model) {
        layer.confirm('您真的要删除这条数据吗?', () => {
            this.server.delete(model, () => {
                layer.msg('删除成功!');
                this.load();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }

    changeSort(model) {
        this.server.changeSort(model, () => {
            layer.msg('修改成功!');
            this.load();
        }, (msg) => {
            layer.msg(msg);
        })
    }

    changeState(model) {
        this.server.changeState(model, () => {
            layer.msg('修改成功!');
            this.load();
        }, (msg) => {
            layer.msg(msg);
        })
    }
}
export default AboutTypeListLayout;
