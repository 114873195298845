import Config from "../framework/config";

class Loading
{
    config = {};

    index = 0;

    layer = {};

    timer = null;

    itTimer = null;

    second = 0;

    enable = true;

    started = false;

    constructor(enable) {
        /* global layer */
        this.layer = layer;
        this.config = (new Config()).request.loading;
        if (enable !== undefined) {
            this.enable = enable;
        }
    }

    start() {
        if (this.enable && this.config.enable && ! this.started && ! this.timer && ! this.itTimer) {
            this.started = true;
            this.timer = setTimeout(() => {
                this.index = this.layer.load(this.config.style);
            }, this.config.startSec);

            this.itTimer = setInterval(() => {
                this.second ++;
                if (this.second >= this.config.endSec) {
                    clearInterval(this.itTimer);
                }
            }, 1000);
        }
    }

    close() {
        if (this.config.enable) {
            this.started = false;
            if (this.timer) {
                clearTimeout(this.timer);
            }
            if (this.itTimer) {
                clearInterval(this.itTimer);
            }
            if (this.index) {
                this.layer.close(this.index);
                this.index = 0;
            }
            if (this.second) {
                this.second = 0;
            }
        }
    }
}
export default Loading;