import Table from "../../layout/table";
import View from "../../../../../vendor/framework/view";
import AboutService from "../../../services/about";
import $ from 'jquery';

class AboutListLayout extends View
{
    service() {
        return AboutService;
    }

    componentDidMount() {
        /* global layer */
    }

    render() {
        return (
            <Table
                service={AboutService}
                searchInit={{
                    keywords:{name:'关键字', enter:true},
                    state:{name:'状态', value:'-1',type: 'select', data: [{key:'-1',name:'--全部--'},{key:1,name:'开启'}, {key:0, name:'禁用'}], option:['key', 'name']}
                }}
                fieldsInit={{
                    id:{name:'ID', checkbox:true, filter:"ids"},
                    typeName:'名称',
                    contentDup:'内容',
                    // sort:{name:'排序', type: 'input', change:this.server.changeSort.bind(this.server)},
                    // state:{name:'状态', type:'switch', checked:1, text:'开启|禁用', change:this.server.changeState.bind(this.server)},
                    createdAt:'添加时间'
                }}
                oprationInit={{
                    edit:{name:'编辑', icon: ['edit'], click: this.edit.bind(this)},
                    delete:{name: '删除', icon:['delete', 'danger'], click:this.delete.bind(this)}
                }}
                btnInit={{
                    add:{name:'添加', click: this.add.bind(this)}
                }}
            />
        );
    }

    add(table) {
        let _this = this,
            layIndex = layer.open({
            title:'添加',
            type:2,
            content:'/admin/about/add',
            area:['80%', '90%'],
            btn:['确定', '取消'],
            maxmin:true,
            min() {
                $('#layui-layer-shade'+layIndex).hide();
            },
            max() {
                $('#layui-layer-shade'+layIndex).show();
            },
            restore() {
                $('#layui-layer-shade'+layIndex).show();
            },
            yes(index, layero) {
                var iframeWindow = window['layui-layer-iframe'+ index]
                    ,submitID = 'LAY-user-front-submit'
                    // eslint-disable-next-line
                    ,submit = layero.find('iframe').contents().find('#'+ submitID);

                //监听提交
                iframeWindow.layui.form.on('submit('+ submitID +')', function(data){
                    var field = data.field; //获取提交的字段
                    _this.server.addOrEdit(field, () => {
                        layer.msg('添加成功!');
                        layer.close(index);
                        table.loadData();
                    }, (msg) => {
                        layer.msg(msg);
                    })
                })
                submit.trigger('click');
            }
        })
    }

    edit(model, table) {
        let _this = this,
        layIndex = layer.open({
            title:'修改'+model.id,
            type:2,
            content:'/admin/about/edit/'+model.id,
            area:['80%', '90%'],
            btn:['确定', '取消'],
            maxmin:true,
            min() {
                $('#layui-layer-shade'+layIndex).hide();
            },
            max() {
                $('#layui-layer-shade'+layIndex).show();
            },
            restore() {
                $('#layui-layer-shade'+layIndex).show();
            },
            yes(index, layero) {
                var iframeWindow = window['layui-layer-iframe'+ index]
                    ,submitID = 'LAY-user-front-submit'
                    // eslint-disable-next-line
                    ,submit = layero.find('iframe').contents().find('#'+ submitID);

                //监听提交
                iframeWindow.layui.form.on('submit('+ submitID +')', function(data){
                    var field = data.field; //获取提交的字段
                    field.id = model.id;
                    _this.server.addOrEdit(field, () => {
                        layer.msg('修改成功!');
                        layer.close(index);
                        table.pageReload();
                    }, (msg) => {
                        layer.msg(msg);
                    })
                })
                submit.trigger('click');
            }
        })
    }

    delete(model, table) {
        layer.confirm('您真的要删除这条数据吗?', () => {
            this.server.delete(model, () => {
                layer.msg('删除成功!');
                table.pageReload();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }
}
export default AboutListLayout;
