import Middleware from "../../../vendor/framework/middleware";
import Auth from "../services/auth";
import { Redirect } from 'react-router'

class GuestMiddleware extends Middleware
{
    auth = new Auth();
    handle($next) {
        if (this.auth.check()) {
            return (
                <Redirect to={"/admin/index"} />
            )
        }
        return $next;
    }
}

export default GuestMiddleware;