import Base from "../../../base";
import RoleListLayout from "../layout";

class RoleChooseList extends Base
{
    render() {
        return (
            <RoleListLayout
                paging={true}
                canCheck={true}
                layForm={true}
                oprationShow={false}
            />
        );
    }
}
export default RoleChooseList;