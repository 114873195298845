import Base from "../../../base";
import RoleListLayout from "../layout";

class RoleList extends Base
{
   render() {
       return (
           <RoleListLayout
                paging={true}
                btnShow={true}
           />
       );
   }
}

export default RoleList;