import View from "../../../../../../vendor/framework/view";
import CooperationService from "../../../../services/running/cooperation";
import Table from "../../../layout/table";

class CooperationListLayout extends View
{
    boot() {
        /* global layer */
        this.status = [
            {key:-1, name: '--全部--'}
        ]
        for(var k in this.server.states) {
            this.status.push({key:Number(k), name:this.server.states[k]});
        }
    }

    service() {
        return CooperationService;
    }

    render() {
        return (
            <Table
                service={CooperationService}
                searchInit={{
                    keywords:{name:'关键字', enter:true},
                    state:{name:'状态', value:'-1',type: 'select', data: this.status, option:['key', 'name']},
                    startTime:{name:'添加时间', placeholder:'开始时间', type: 'date', format:'datetime', position:0},
                    endTime:{placeholder: '结束时间', type:'date',  format:'datetime', position:1}
                }}
                fieldsInit={{
                    id:'ID',
                    name:'姓名',
                    phone:'手机号码',
                    desc:'意向说明',
                    stateDup:'状态',
                    createdAt:'添加时间',
                    updatedAt:'更新时间'
                }}
                oprationInit={{
                    audit:{name:'审核', disabled:'canAudit', icon:['search', 'warm'], click:this.audit.bind(this)},
                    delete:{name: '删除', icon:['delete', 'danger'], click:this.delete.bind(this)}
                }}
            />
        );
    }

    audit(model, table) {
        let _this = this;
        if (model.canAudit) {
            layer.confirm('您真的要审核 '+model.name+' 的申请吗?', {btn:['通过', '拒绝', '取消']}, () => {
                audit(1);
            }, () => {
                audit(2);
            })
        }

        function audit(state) {
            _this.server.changeState({id:model.id,state:state}, () => {
                layer.msg('审核成功!');
                table.pageReload();
            }, (msg) => {
                layer.msg(msg);
            })
        }
    }

    delete(model, table) {
        layer.confirm('您真的要删除 '+model.name+' 的申请吗?', () => {
            this.server.delete(model, () => {
                layer.msg('删除成功!');
                table.pageReload();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }
}
export default CooperationListLayout;
