import Base from "../../../base";
import AboutTypeListLayout from "../layout";

class AboutTypeList extends Base
{
    render() {
        return (
            <AboutTypeListLayout
                paging={false}
            />
        );
    }
}
export default AboutTypeList;
