import env from '../../env.json';

class Config
{
    serverUrl = this.env('serverUrl');

    uploadUrl = this.env('uploadUrl');

    debug = this.env('debug', false);

    upload = {
        types:['image', 'video', 'word', 'excel'],
        imageFormats:['jpg', 'bmp', 'png', 'gif'],
        videoFormats:['mp4', 'mkv'],
        excelFormats:['xlsx']
    }

    request = {
        loading:{
            enable:this.env('request.loading.enable', false),
            style:this.env('request.loading.style', 1),
            startSec:this.env('request.loading.startSec', 2000),
            endSec:this.env('request.loading.endSec', 20000),
            errMsg:this.env('request.loading.errMsg'),
            errAlert:this.env('request.loading.errAlert', 'msg')
        },
        type:this.env('request.type', 'POST'),
        async:this.env('request.async', true),
        dataType:this.env('request.dataType', 'JSON'),
        contentType:this.env('request.contentType', 'application/json'),
        successCode:this.env('request.successCode', 200),
        notLoginCode:this.env('request.notLoginCode'),
        notRuleCode:this.env('request.notRuleCode')
    }

    env(key, def = '') {
        var keys = key.split('.');
        var th = {};
        for(var k in env) {
            th[k] = env[k];
        }
        return this.conEnv(th, keys) || def;
    }

    conEnv(val, keys) {
        for(var k in keys) {
            val = val[keys[k]];
            if (['object', 'array'].indexOf(typeof val) === -1) {
                break;
            }
        }
        return val;
    }

    getServerUrl() {
        let environment = this.env('environment'),
            server = 'serverUrl',
            url = environment ? (environment + server.charAt(0).toUpperCase() + server.slice(1)) : server;
            return this.env(url);
    }
}

export default Config;