import Base from "../../base";
import ProductListLayout from "../layout";

class ProductList extends Base
{
    render() {
        return (
            <ProductListLayout
                paging={true}
                btnShow={true}
                searchShow={true}
            />
        );
    }
}
export default ProductList;
