import Base from "../../base";
import Form from "../../layout/form";
import OprationService from "../../../services/opration";
import OprationTypeService from "../../../services/opration/type";

class OprationEdit extends Base
{
    boot() {
        this.state = {
            model:{
                type:'',
                name:'',
                link:'',
                sort:0,
                state:1
            },
            types:[]
        }
    }

    service() {
        return OprationService;
    }

    componentDidMount() {
        this.server.getDetail(this.getPropsParams('id'), (res) => {
            this.setModel(res.data);
        })
        this.server.getService(OprationTypeService).getList((res) => {
            this.setState({
                types:res.data
            })
        })
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={
                    {
                        type:{name:'类型', type:'select', data: this.state.types, option:['id', 'name']},
                        name: '名称',
                        link:{name:'链接', type: 'textarea'},
                        sort: {name: '排序', type: 'number'},
                        state: {name: '状态', type: 'select', data: this.server.states}
                    }
                }
            />
        );
    }
}
export default OprationEdit;
