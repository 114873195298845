import Middleware from "../../../vendor/framework/middleware";
import Auth from "../services/auth";
import App from "../../../vendor/framework/app";
import AdminService from "../services/admin";

class PermissionMiddleware extends Middleware
{
    auth = new Auth();

    app = new App();

    admin = new AdminService();

    handle(props) {
        let path = props.match.path;

        if (this.admin.leaders.indexOf(this.auth.getUser('username')) === -1) {
            this.auth.getRules((data) => {
                let paths = [];
                for(var k of data) {
                    if (k.viewPath) {
                        paths.push(k.viewPath);
                    }
                }
                if (paths.indexOf(path) < 0) {
                    this.app.redirect('/admin/error');
                }
            })
        }
    }
}
export default PermissionMiddleware;
