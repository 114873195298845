import Route from '../vendor/framework/route';
import Login from "../app/admin/views/login";
import Index from "../app/admin/views/index";
import UserList from "../app/admin/views/user/list";
import AdminList from "../app/admin/views/administraitor/admin/list";
import AdminAdd from "../app/admin/views/administraitor/admin/add";
import AdminEdit from "../app/admin/views/administraitor/admin/edit";
import AdminInformationDetail from "../app/admin/views/administraitor/admin/information/list";
import AdminInformationEdit from "../app/admin/views/administraitor/admin/information/edit";
import RoleList from "../app/admin/views/administraitor/role/list";
import RoleAdd from "../app/admin/views/administraitor/role/add";
import RoleEdit from "../app/admin/views/administraitor/role/edit";
import RuleList from "../app/admin/views/administraitor/rule/list";
import RuleAdd from "../app/admin/views/administraitor/rule/add";
import AuthMiddleware from "../app/admin/middlewares/auth";
import GuestMiddleware from "../app/admin/middlewares/guest";
import RuleIcon from "../app/admin/views/administraitor/rule/icon";
import RuleEdit from "../app/admin/views/administraitor/rule/edit";
import RoleChooseList from "../app/admin/views/administraitor/role/choose";
import Error from "../app/admin/views/index/error";
import ProductList from "../app/admin/views/product/list";
import ProductAdd from "../app/admin/views/product/add";
import ProductEdit from "../app/admin/views/product/edit";
import NewsList from "../app/admin/views/news/list";
import NewsAdd from "../app/admin/views/news/add";
import NewsEdit from "../app/admin/views/news/edit";
import AboutList from "../app/admin/views/about/list";
import AboutTypeList from "../app/admin/views/about/type/list";
import AboutTypeAdd from "../app/admin/views/about/type/add";
import AboutTypeEdit from "../app/admin/views/about/type/edit";
import AboutAdd from "../app/admin/views/about/add";
import AboutEdit from "../app/admin/views/about/edit";
import CooperationList from "../app/admin/views/running/cooperation/list";
import OprationList from "../app/admin/views/opration/list";
import OprationTypeList from "../app/admin/views/opration/type/list";
import OprationTypeAdd from "../app/admin/views/opration/type/add";
import OprationTypeEdit from "../app/admin/views/opration/type/edit";
import OprationAdd from "../app/admin/views/opration/add";
import OprationEdit from "../app/admin/views/opration/edit";
import BannerList from "../app/admin/views/running/banner/list";
import BannerAdd from "../app/admin/views/running/banner/add";
import BannerEdit from "../app/admin/views/running/banner/edit";
import ConfigList from "../app/admin/views/running/config/list";
import SeoList from "../app/admin/views/seo/list";
import SeoAdd from "../app/admin/views/seo/add";
import SeoEdit from "../app/admin/views/seo/edit";

var namespace = '/admin';

var paths = [
    {
        path: '/login',
        component: Login,
        middleware: [new GuestMiddleware()]
    },
    {
        path: '/',
        middleware: [new AuthMiddleware()],
        children: [
            {
                path: '/index',
                component: Index
            },
            {
                path:'/error',
                component: Error
            },
            {
                path:'/',
                middleware:[],
                children:[
                    {
                        path: '/user/list',
                        component: UserList
                    },
                    {
                        path: '/admin/list',
                        component: AdminList
                    },
                    {
                        path: '/admin/add',
                        component: AdminAdd
                    },
                    {
                        path: '/admin/edit/:id',
                        component: AdminEdit
                    },
                    {
                        path: '/admin/information',
                        component: AdminInformationDetail
                    },
                    {
                        path: '/admin/password/edit',
                        component: AdminInformationEdit
                    },
                    {
                        path: '/role/list',
                        component: RoleList
                    },
                    {
                        path: '/role/add',
                        component: RoleAdd
                    },
                    {
                        path: '/role/edit/:id',
                        component: RoleEdit
                    },
                    {
                        path:'/role/choose/list',
                        component: RoleChooseList
                    },
                    {
                        path: '/rule/list',
                        component: RuleList
                    },
                    {
                        path:'/rule/icon',
                        component: RuleIcon
                    },
                    {
                        path: '/rule/add/:pid?',
                        component: RuleAdd
                    },
                    {
                        path: '/rule/edit/:id',
                        component: RuleEdit
                    },
                    {
                        path:'/product/list',
                        component: ProductList
                    },
                    {
                        path:'/product/add',
                        component: ProductAdd
                    },
                    {
                        path:'/product/edit/:id',
                        component: ProductEdit
                    },
                    {
                        path:'/news/list',
                        component: NewsList
                    },
                    {
                        path:'/news/add',
                        component: NewsAdd
                    },
                    {
                        path:'/news/edit/:id',
                        component: NewsEdit
                    },
                    {
                        path:'/about/list',
                        component: AboutList
                    },
                    {
                        path:'/about/add',
                        component: AboutAdd
                    },
                    {
                        path:'/about/edit/:id',
                        component: AboutEdit
                    },
                    {
                        path:'/about/type/list',
                        component: AboutTypeList
                    },
                    {
                        path:'/about/type/add',
                        component: AboutTypeAdd
                    },
                    {
                        path:'/about/type/edit/:id',
                        component: AboutTypeEdit
                    },
                    {
                        path:'/cooperation/list',
                        component: CooperationList
                    },
                    {
                        path:'/opration/list',
                        component: OprationList
                    },
                    {
                        path:'/opration/add',
                        component: OprationAdd
                    },
                    {
                        path:'/opration/edit/:id',
                        component: OprationEdit
                    },
                    {
                        path:'/opration/type/list',
                        component: OprationTypeList
                    },
                    {
                        path:'/opration/type/add',
                        component: OprationTypeAdd
                    },
                    {
                        path:'/opration/type/edit/:id',
                        component: OprationTypeEdit
                    },
                    {
                        path:'/banner/list',
                        component: BannerList
                    },
                    {
                        path:'/banner/add',
                        component: BannerAdd
                    },
                    {
                        path:'/banner/edit/:id',
                        component: BannerEdit
                    },
                    {
                        path:'/config/list',
                        component: ConfigList
                    },
                    {
                        path:'/seo/list',
                        component: SeoList
                    },
                    {
                        path:'/seo/add',
                        component: SeoAdd
                    },
                    {
                        path:'/seo/edit/:id',
                        component: SeoEdit
                    }
                ]
            },
        ]
    },

];

export default class Admin extends Route {
    constructor(props) {
        super(props);
        this.namespace = namespace;
        this.paths = paths;
    }
}
