import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";

class SeoService extends BaseService
{
    requestUrls = {
        url:'/seo/list',
        addOrEditUrl:'/seo/addOrEdit',
        detailUrl:'/seo/detail',
        deleteUrl:'/seo/delete',
        typeUrl:'/seo/types'
    }

    appends = ['titleDup', 'keywordsDup', 'descriptionDup'];

    getTypes(suc) {
        this.request({
            url:this.requestUrls.typeUrl,
            makeModel:false,
            success:suc
        })
    }

    addOrEdit(model, suc, err) {
        let validator = new Validator(),
            rules = [
                ['type', 'required|gt:0', '请选择页面|请选择页面'],
                ['title', 'required', '请填写标题'],
            ];
        if (! validator.validate(rules, model)) {
            return err(validator.getError());
        }

        this.post(
            this.requestUrls.addOrEditUrl,
            model,
            suc,
            err
        )
    }

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detailUrl,
            {id:id},
            suc
        )
    }

    delete(model, suc, err) {
        this.post(
            this.requestUrls.deleteUrl,
            {id:model.id},
            suc,
            err
        )
    }

    getTitleDupAttribute() {
        var content = this.attributes['title'];
        var length = content.length;
        return length > 20 ? (content.substr(0, 17) + '...') : content;
    }

    getKeywordsDupAttribute() {
        var content = this.attributes['keywords'];
        var length = content.length;
        return length > 20 ? (content.substr(0, 17) + '...') : content;
    }

    getDescriptionDupAttribute() {
        var content = this.attributes['description'];
        var length = content.length;
        return length > 20 ? (content.substr(0, 17) + '...') : content;
    }
}
export default SeoService;
