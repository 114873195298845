import BaseService from "../../base";

class ConfigService extends BaseService
{
    requestUrls = {
        url:'/config/list',
        editUrl:'/config/edit'
    }

    getList(suc) {
        this.post(
            this.requestUrls.url,
            {},
            suc
        )
    }

    edit(model, suc, err) {
        this.post(
            this.requestUrls.editUrl,
            model,
            suc,
            err
        )
    }
}
export default ConfigService;
