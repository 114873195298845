import Base from "../../../base";
import RuleService from "../../../../services/admin/rule";
import style from "../icon/index.module.css";
import RuleSort from "../../../../components/ruleSort";

class RuleEdit extends Base
{
    boot() {
        this.state = {
            model:{
                icon:{},
                name:'',
                pid:0,
                allowView:1,
                allowApi:1,
                apiPath:'',
                viewPath:''
            },
            list:[],
            siblings:[],
        }

        this.requireCss([
            '/iconfont/demo.css',
            'https://g.alicdn.com/thx/cube/1.3.2/cube.min.css'
        ])
    }

    service() {
        return RuleService;
    }

    componentDidMount() {
        /* global layui,layer, $ */
        layui.use(['form'], () => {
            let form = layui.form;

            this.server.getCanForParent((data) => {
                this.server.getDetail(this.getPropsParams('id'), (res) => {
                    let siblings = this.server.getSiblings(res.data.pid, data);
                    this.setState({
                        list:data,
                        model:res.data,
                        siblings: this.server.siblingsSort(siblings)
                    }, () => {
                        form.render();
                    })
                })
            })

            form.on('select(pid)', (data) => {
                this.setModel('pid', Number(data.value), () => {
                    form.render();
                });
            })
            form.on('switch(isMenu)', (data) => {
                let value = $(data.elem).prop('checked') ? 1 : 0;
                this.setModel('isMenu', value);
            })
            form.on('switch(allowApi)', (data) => {
                let value = $(data.elem).prop('checked') ? 1 : 0;
                this.setModel('allowApi', value);
            })
            form.on('switch(allowView)', (data) => {
                let value = $(data.elem).prop('checked') ? 1 : 0;
                this.setModel('allowView', value);
            })
        })
    }

    render() {
        return (
            <div className="layui-form" lay-filter="layuiadmin-form-useradmin" id="layuiadmin-form-useradmin" style={{padding: '20px 30px 0 0'}}>
                <div className="layui-form-item">
                    <label className="layui-form-label">父级</label>
                    <div className="layui-input-inline">
                        <select name="pid" lay-filter="pid" lay-search={""}value={this.state.model.pid}>
                            <option value={0}>顶级</option>
                            {
                                this.state.list.map((rule, index) => {
                                    if (rule.id !== this.state.model.id && rule.pid !== this.state.model.id) {
                                        return (
                                            <option key={index} value={rule.id}>{rule.name}</option>
                                        )
                                    }
                                    return '';
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="layui-form-item">
                    <label className="layui-form-label">权限名称</label>
                    <div className="layui-input-inline">
                        <input type="text" name="name" onChange={e => this.onChangeModel(e)} autocomplete="off" value={this.state.model.name} className={"layui-input ruleName"} />
                    </div>
                </div>
                <div className="layui-form-item">
                    <label className="layui-form-label">作为菜单</label>
                    <div className="layui-input-block">
                        <input type="checkbox" name="isMenu" checked={this.state.model.isMenu} lay-filter={"isMenu"} lay-skin="switch" value={this.state.model.isMenu} lay-text="是|否" />
                    </div>
                </div>
                <div className={"layui-form-item "+( ! this.state.model.pid && this.state.model.isMenu ? '' : 'layui-hide')}>
                    <label className="layui-form-label">图标</label>
                    <div className="layui-input-block">
                        <button type="button" onClick={e => this.chooseIcon()} className="layui-btn layui-btn-normal">选择</button>
                        <input type="text" name="icon" value={JSON.stringify(this.state.model.icon) || ''} style={{display:'none'}} />
                        {
                            this.state.model.icon.className ?
                                (
                                    <div className={"dib "+style.dib}>
                                        <span className={"icon iconfont "+this.state.model.icon.className}></span>
                                        <div className="name">
                                            {this.state.model.icon.name}
                                        </div>
                                        <div className="code-name">.{this.state.model.icon.className}
                                        </div>
                                    </div>
                                )
                                :''
                        }
                    </div>
                </div>
                <div className="layui-form-item">
                    <label className="layui-form-label">访问页面</label>
                    <div className="layui-input-block">
                        <input type="checkbox" name="allowView" checked={this.state.model.allowView} lay-filter={"allowView"} lay-skin="switch" value={this.state.model.allowView} lay-text="是|否" />
                    </div>
                </div>
                <div className="layui-form-item">
                    <label className="layui-form-label">访问接口</label>
                    <div className="layui-input-block">
                        <input type="checkbox" name="allowApi" checked={this.state.model.allowApi} lay-filter={"allowApi"} lay-skin="switch" value={this.state.model.allowApi} lay-text="是|否" />
                    </div>
                </div>
                <div className={"layui-form-item "+ (this.state.model.allowView ? '' : 'layui-hide')}>
                    <label className="layui-form-label">页面路径</label>
                    <div className="layui-input-inline">
                        <input type="text" name="viewPath" onChange={e => this.onChangeModel(e)} placeholder="请输入路径" autoComplete={"off"} value={this.state.model.viewPath} className="layui-input" />
                    </div>
                </div>
                <div className={"layui-form-item "+ (this.state.model.allowApi ? '' : 'layui-hide')}>
                    <label className="layui-form-label">接口路径</label>
                    <div className="layui-input-inline">
                        <input type="text" name="apiPath" onChange={e => this.onChangeModel(e)} placeholder="请输入路径" autoComplete={"off"} value={this.state.model.apiPath} className="layui-input" />
                    </div>
                </div>
                <div className="layui-form-item">
                    <label className="layui-form-label">描述</label>
                    <div className="layui-input-block">
                        <textarea name="desc" className="layui-textarea" onChange={e => this.onChangeModel(e)} value={this.state.model.desc}></textarea>
                    </div>
                </div>
                <div className="layui-form-item">
                    <label className="layui-form-label">排序</label>
                    <div className="layui-input-block" >
                        <RuleSort data={this.state.siblings} />
                    </div>
                </div>
                <div className="layui-form-item layui-hide">
                    <input type="button" lay-submit={""} lay-filter="LAY-user-front-submit" id="LAY-user-front-submit"
                           value="确认" />
                </div>
            </div>
        );
    }

    chooseIcon() {
        let self = this;
        layer.open({
            type:2,
            title:'选择标题',
            content:'/admin/rule/icon',
            btn:['确定', '取消'],
            area:['90%', '90%'],
            yes: function(index, layero){
                var iframeWindow = window['layui-layer-iframe'+ index]
                    ,submitID = 'LAY-user-front-submit'
                    ,submit = layero.find('iframe').contents().find('#'+ submitID);

                //监听提交
                iframeWindow.layui.form.on('submit('+ submitID +')', function(data){

                    let icon = data.field.icon;
                    if (! icon) {
                        return layer.msg('请选择图标');
                    }
                    self.setModel('icon', JSON.parse(icon));
                    layer.close(index);
                });
                submit.trigger('click');
            }
        })
    }

}
export default RuleEdit;
