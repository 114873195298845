import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";

class RoleService extends BaseService
{
    requestUrls = {
        url:'/role/list',
        addOrEditUrl:'/role/addOrEdit',
        detailUrl:'/role/detail',
        deleteUrl: '/role/delete'
    }

    states = ['停用', '正常'];

    appends = ['stateDup', 'descDup'];

    getList(conf) {
        return this.view.getPage(conf);
    }

    addOrEdit(model, suc, err) {
        let validator = new Validator(),
            rules = [
                ['name', 'required', '请输入角色名称'],
            ];

        model = this.app.htmlArrayToJson(model);
        if (! validator.validate(rules, model)) {
            return err(validator.getError());
        }
        this.post(this.requestUrls.addOrEditUrl,
                model,
                suc,
                err
            )
    }

    getDetail(id, suc) {
        this.post(this.requestUrls.detailUrl, {id:id}, suc);
    }

    delete(id, suc, err) {
        this.post(
            this.requestUrls.deleteUrl,
            {id:id},
            suc,
            err
        )
    }

    methods() {
        return {
            canDelete() {
                return true;
            }
        }
    }

    getStateDupAttribute() {
        return this.states[this.attributes['status']];
    }

    getDescDupAttribute(val, model) {
        return model.desc;
    }
}
export default RoleService;
