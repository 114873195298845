import View from "../../../../vendor/framework/view";
import style from "./index.module.css";
import { Fragment } from 'react';

class RuleSort extends View
{
    boot() {
        this.state = {
            data:[]
        }

        this.requireJs([
            '/plugins/dragsort/jquery.dragsort-0.5.2.js'
        ])

    }

    componentDidMount() {
        /* global $ */
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            data:nextProps.data
        }, () => {
            this.init();
        })
    }

    render() {
        return (
            <Fragment>
                <ul className={style.list}>
                    {
                        this.state.data.map((val, index) => {
                            return (
                                <li key={index}><div><span data-id={val.id}>{val.name}</span></div></li>
                            )
                        })
                    }
                </ul>
                <div className={"sortBox"}>

                </div>
            </Fragment>
        );
    }

    init() {

        $("."+style.list).dragsort({ dragSelector: "div", dragBetween: true, dragEnd: () => {
                var data = $("."+style.list+" li").map(function() { return $(this).children().html(); }).get();
                var sortsInput = "";
                for(var k in data) {
                    // list.push({
                    //     id:$(data[k]).attr('data-id'),
                    //     sort:k
                    // });
                    sortsInput += "<input type='hidden' name=sorts[id"+$(data[k]).attr('data-id')+"] value="+k+" />"
                }

                $('.sortBox').html(sortsInput);
            }, placeHolderTemplate: "<li class="+style.placeHolder+"><div></div></li>" });
    }
}
export default RuleSort;
