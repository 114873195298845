import Base from "../../base";
import AboutService from "../../../services/about";
import Form from "../../layout/form";
import AboutTypeService from "../../../services/about/type";

class AboutEdit extends Base
{
    boot() {
        this.state = {
            model:{
                state:1,
                sort:0
            },
            types:[]
        }
    }

    service() {
        return AboutService;
    }

    componentDidMount() {
        this.server.getService(AboutTypeService).getList((res) => {
            this.setState({
                types:res.data
            })
        })
        this.server.getDetail(this.getPropsParams('id'), (res) => {
            this.setModel(res.data);
        })
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={{
                    type: {name:'类型', type: 'select', option: ['id', 'name'], data: this.state.types},
                    content:{name:'内容', type :'editor'},
                    // sort:'排序',
                    // state:{name:'状态', type: 'radio', data: this.server.states}
                }}
            />
        );
    }
}
export default AboutEdit;
