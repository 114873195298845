import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import MiddlewareModel from "./middleware";

class Router extends React.Component
{
    constructor(props) {
        super(props);
        this.namespace = '';
        this.paths = [];
        this.middleware = new MiddlewareModel();
    }

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    {this.handelChildren(this.paths, this.namespace)}
                </Switch>
            </BrowserRouter>
        );
    }

    handelChildren(paths, namespace, middleware) {
        return paths.map((val, index) => {
            var rightSign = namespace.substr(namespace.length -1, 1);
            if (rightSign === '/') {
                namespace = namespace.substr(0, namespace.length - 1);
            }

            if (middleware && middleware.length) {
                val.middleware = val.middleware && val.middleware.length ? val.middleware : [];
                //父级中件传递至子级中间件
                for(var k in middleware) {
                    val.middleware.push(middleware[k]);
                }
            }

            let path = namespace + val.path;
            let children;
            let route;
            //处理子级和子级中间件
            if (val.children && val.children.length) {

                children = this.handelChildren(val.children, path, val.middleware);
                route = (
                    <Route key={index} path={path} render={() =>children} />
                )
            } else {
                //执行中间件
                if (val.middleware && val.middleware.length) {
                    route = val.middleware.map((v) => {
                        var m = v;
                        var r = m.handle(this.middleware, val);
                        if (r instanceof MiddlewareModel) {
                            return (
                                <Route key={index} path={path} exact={true} component={val.component} />
                            )
                        } else {
                            return (
                                <Route key={index} path={path} render={() => r} />
                            )
                        }
                    })
                } else {
                    route = (
                        <Route key={index} path={path} exact={true} component={val.component} />
                    )
                }
            }
            return route;
        })
    }
}

export default Router;