import React from 'react'

class TableEmpty extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            defaultContent:'暂无数据',
            defaultAlign:'center'
        }
    }

    render() {
        var tr,
            empty,
            isShow = this.props.isShow === true;

         tr = (
             <tr>
                 <td colSpan={this.props.colSpan} align={this.props.align || this.state.defaultAlign}>{this.props.content || this.state.defaultContent}</td>
             </tr>
         )

        empty = (
            <tr style={{display:'none'}}></tr>
        )
        return isShow ? tr : empty;
    }
}

export default TableEmpty;