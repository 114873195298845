import Base from "../../../base";
import AdminService from "../../../../services/admin";
import RoleListLayout from "../../role/layout";
import RoleService from "../../../../services/admin/role";
import style from './index.module.css';

class AdminEdit extends Base
{
    boot() {
        this.state = {
            model:{
                status:0
            },
            role:''
        }
    }

    service() {
        return AdminService;
    }

    componentDidMount() {
        /* global layui,layer */
        layui.use(['form'], () => {
            let form = layui.form;

            this.server.getDetail(this.getPropsParams('id'), (res) => {
                this.server.getService(RoleService).getDetail(res.data.roleId, (resp) => {
                    this.setState({
                        model:res.data,
                        role:resp.data
                    }, () => {
                        form.render();
                    })
                })
            })

            form.on('select(status)', (data) => {
                this.setModel('status', Number(data.value));
            })
        })
    }

    render() {
        return (
            <div className="layui-form" style={{padding: '20px 30px 0 0'}}>
                <div className="layui-form-item">
                    <label className="layui-form-label">账号</label>
                    <div className="layui-input-inline">
                        <input type="text" name="username" autoComplete="off"
                               className="layui-input" disabled={! this.state.model.canEditUsername} onChange={e => this.onChangeModel(e)} value={this.state.model.username} />
                    </div>
                    <span className={style.tip}>{! this.state.model.canEditUsername ? '此帐号不能修改' : ''}</span>
                </div>
                <div className="layui-form-item">
                    <label className="layui-form-label">密码</label>
                    <div className="layui-input-inline">
                        <input type="password" name="password" autoComplete="off"
                               onChange={e => this.onChangeModel(e)} value={this.state.model.password}
                               className="layui-input" />
                    </div>
                </div>

                <div className="layui-form-item">
                    <label className="layui-form-label">确认密码</label>
                    <div className="layui-input-inline">
                        <input type="password" name="passwordConfirmation" autoComplete="off" className="layui-input"
                               onChange={e => this.onChangeModel(e)} value={this.state.model.passwordConfirmation} />
                    </div>
                </div>
                <div className="layui-form-item">
                    <label className="layui-form-label">角色</label>
                    <div className="layui-input-block">
                        <button type="button" onClick={e => this.chooseRole()} className="layui-btn layui-btn-normal">选择</button>
                        <RoleListLayout
                            btnShow={false}
                            show={this.state.model.roleId}
                            list={[this.state.role]}
                            autoload={false}
                            oprationShow={false}
                            inputName={'roleId'}
                        />
                    </div>
                </div>
                <div className="layui-form-item">
                    <label className="layui-form-label">状态</label>
                    <div className="layui-input-inline">
                        <select name="status" value={this.state.model.status}>
                            {
                                this.server.states.map((val, index) => {
                                    return (
                                        <option key={index} value={index}>{val}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="layui-form-item layui-hide">
                    <input type="button" lay-submit={""} lay-filter="LAY-user-front-submit" id="LAY-user-front-submit"
                           value="确认" />
                </div>
            </div>
        );
    }

    chooseRole() {
        let self = this;
        layer.open({
            title:'选择角色',
            type:2,
            content:'/admin/role/choose/list',
            btn:['确定', '取消'],
            area:['98%', '90%'],
            yes(index, layero) {
                var iframeWindow = window['layui-layer-iframe'+ index]
                    ,submitID = 'LAY-user-front-submit'
                    ,submit = layero.find('iframe').contents().find('#'+ submitID);

                //监听提交
                iframeWindow.layui.form.on('submit('+ submitID +')', function(data){
                    var field = self.app.htmlArrayToJson(data.field), //获取提交的字段
                        user = field.data,
                        role;

                    if (! user || !user.length) {
                        layer.msg('请选择角色!');
                        return;
                    }

                    for(var k in user) {
                        role = JSON.parse(user[k]);
                    }

                    self.setState( state =>{
                        let model = state.model;
                        model.roleId = role.id;
                        return {
                            role:role,
                            model:model
                        }
                    })

                    layer.close(index)
                })
                submit.trigger('click');
            }
        })
    }
}

export default AdminEdit;
