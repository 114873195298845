import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";

class ProductService extends BaseService
{
    requestUrls = {
        url:'/product/list',
        addOrEditUrl:'/product/addOrEdit',
        detailUrl:'/product/detail',
        updateStateUrl:'/product/updateState',
        updateSortUrl:'/product/updateSort',
        deleteUrl:'/product/delete'
    }

    states = ['禁用', '启用'];

    types = ['无', '新品', '火热'];

    appends = ['stateDup', 'typeDup'];

    addOrEdit(model, suc, err) {
        let validator = new Validator(),
            rules = [
                ['name', 'required', '请填写产品名称'],
                ['desc', 'required', '请填写描述'],
                ['icon', 'required', '请上传icon'],
            ];
        if (! validator.validate(rules, model)) {
            return err(validator.getError());
        }

        this.post(
            this.requestUrls.addOrEditUrl,
            model,
            suc,
            err
        )
    }

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detailUrl,
            {id:id},
            suc
        )
    }

    delete(model, suc, err) {
        this.post(
            this.requestUrls.deleteUrl,
            {id:model.id},
            suc,
            err
        )
    }

    changeState(data, suc, err) {
        let model = {
            id:data.id,
            state:Number(data.state) === 0 ? 1 : 0
        }
        this.post(
            this.requestUrls.updateStateUrl,
            model,
            suc,
            err
        )
    }

    changeSort(data, suc, err) {
        let model = {
            id:data.id,
            sort:data.sort
        }
        this.post(
            this.requestUrls.updateSortUrl,
            model,
            suc,
            err
        )
    }

    getStateDupAttribute() {
        return this.states[this.attributes['state']];
    }

    getTypeDupAttribute() {
        return this.types[this.attributes['type']];
    }
}
export default ProductService;
