import ReactDOM from 'react-dom';
import Admin from './router/admin';

ReactDOM.render(
    <div>
        <Admin />
    </div>,
  document.getElementById('root')
);


