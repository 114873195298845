import $ from "jquery";
import Request from "./request";
import Config from "./config";

class File
{
    config = new Config();

    upload(config) {
        var self = this,
            keyName = 'file',
            className = 'app-upload-file',
            input = '<input style="display:none;" type=file '+(config.multiple ? "multiple=multiple" : '') + ' class='+className+'>';
        var inputOjb = $(input);

        $(document).on('click', config.btn, function() {
            inputOjb.val('');
            inputOjb.click();
        })

        inputOjb.change(function() {
            var files = $(this)[0].files,
                uploadConfig = self.config.upload,
                fielNames = [],
                fileExts = [],
                fileSizes = [],
                fileType = config.fileType,
                types = uploadConfig.types;
            config.exts = config.exts || [];
            config.exts = typeof config.exts === 'string' ? config.exts.split(',') : config.exts;

            if (fileType) {
                fileType = typeof fileType === 'string' ? fileType.split(',') : fileType;
                for(var k in fileType) {
                    var tIndex = types.indexOf(fileType[k]);
                    if (tIndex >= 0) {
                        var format = types[tIndex];
                        var formats = uploadConfig[format+ 'Formats'];
                        if (formats) {
                            formats = typeof formats === 'string' ? formats.split(',') : formats;
                            for(var j in formats) {
                                config.exts.push(formats[j]);
                            }
                        }
                    }
                }
            }
            // eslint-disable-next-line
            for (var k of files) {
                var nameArr = k.name ? k.name.split('.') : [],
                    ext = nameArr[nameArr.length - 1] || '',
                    exts = [],
                    units = ['K', 'M', 'G'],
                    times = [1024, 1024 * 1024, 1024 * 1024 * 1024];
                nameArr.pop();

                fielNames.push(nameArr.join('.'));
                fileExts.push(ext);
                fileSizes.push(k.size);
                if (config.exts) {
                    // eslint-disable-next-line
                    for(var j in config.exts) {
                        exts.push(config.exts[j].toUpperCase());
                    }
                    if (exts.indexOf(ext.toUpperCase()) < 0) {
                        if (config.fileErr) {
                            config.fileErr(config.extErr || '文件类型不允许', 'type', exts, ext);
                        }
                        return;
                    }
                }

                if (config.maxSize) {
                    var size = config.maxSize;
                    var unit = size.replace(/[^a-z|A-Z]/ig,"");
                    var max = size.replace(/[^0-9]/ig,"");
                    if (unit) {
                        unit = unit.toUpperCase();
                        max = max * (times[units.indexOf(unit)] || 1);
                    }

                    if (k.size > max) {
                        if (config.fileErr) {
                            config.fileErr(config.maxErr || '文件大小超过限制', 'sizeMax', max, k.size);
                        }
                        return;
                    }
                }

                if (config.minSize) {
                    // eslint-disable-next-line
                    var size = config.minSize;
                    // eslint-disable-next-line
                    var unit = size.replace(/[^a-z|A-Z]/ig,"");
                    var min = size.replace(/[^0-9]/ig,"");
                    if (unit) {
                        unit = unit.toUpperCase();
                        min = min * (times[units.indexOf(unit)] || 1);
                    }
                    if (k.size < min) {
                        if (config.fileErr) {
                            config.fileErr(config.minErr || '文件大小超过限制', 'sizeMin', min, k.size);
                        }
                        return;
                    }
                }
            }

            //选中后事件回调
            if (config.choosed && typeof config.choosed === 'function') {
                config.choosed(files, fielNames, fileExts, fileSizes);
            }

            config.url = config.url || self.config.uploadUrl;
            var request = new Request();
            // eslint-disable-next-line
            for(var k of files) {
                var form = new FormData();
                form.append(keyName, k);
                config.form = form;
                request.native(config);
            }
        })
    }
}
export default File;
