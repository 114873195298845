import React from 'react';
import App from './app';
import $ from 'jquery'
import Page from "../utils/page";

class View extends React.Component
{
    app = new App();

	server;

	middleware = [];

    constructor(props) {
		super(props);
		this.loadServer();
		this.loadBoot();
	}

	componentWillMount() {
		if (this.middleware.length) {
			for(var k of this.middleware) {
				k.handle(this.props);
			}
		}
	}

	loadServer() {
		if (this.service && typeof this.service === 'function') {
			var server = this.service();
			this.server = server && new server(this);
		}
	}

	loadBoot() {
		if (this.boot && typeof this.boot === 'function') {
			this.boot(this.props);
		}
	}

	requireJs(arr) {
		arr = typeof arr === 'string' ? [arr] : arr;
		for(var k in arr) {
			var script = document.createElement('script');
			script.type = 'text/javascript';
			script.async = true;
			script.src = arr[k];
			document.head.appendChild(script);
		}
	}

	requireCss(arr) {
		arr = typeof arr === 'string' ? [arr] : arr;
		for(var k in arr) {
			var link = document.createElement('link');
			link.type='text/css';
			link.rel = 'stylesheet';
			link.href = arr[k];
			document.head.appendChild(link);
		}
	}

	getCheckIds(className = 'ids') {
		var ids = [];
		$('.'+className).each(function(){
			if ($(this).is(':checked') === true) {
				var obj = JSON.parse($(this).val())
				ids.push(obj.id);
			}
		})
		return ids;
	}

	setSearch(key, val) {
		let search = this.state.search;
		if (typeof key === 'object') {
			for(var k in key) {
				search[k] = key[k];
			}
		} else {
			search[key] = val;
		}
		this.setState({
			search:search
		})
	}

	getSearch(key) {
		return this.state.search[key];
	}

	getModel(key) {
		return this.state.model[key];
	}

	setModel(key, val, suc) {
		let model = this.state.model;
		if (typeof key === 'object') {
			for(var k in key) {
				model[k] = key[k];
			}
		} else {
			model[key] = val;
		}

		this.setState({
			model:model
		}, () => {
			suc && typeof suc === 'function' && suc();
		})
	}

	onChangeSearch(e) {
		this.setSearch(e.target.name, e.target.value);
	}

	onChangeModel(e) {
		this.setModel(e.target.name, e.target.value);
	}

	onEnter(e, func) {
		if (e.which === 13) {
			if (typeof func === 'function') {
				func();
			}
		}
	}

	getPage(conf = {}) {
		return (new Page(this)).render(conf);
	}

	getPropsParams(key) {
    	let params = this.props.match.params;
    	if (key) {
    		return params[key];
		}
    	return params;
	}

	developing(msg) {
		// eslint-disable-next-line
    	/* global layer */
		layer.msg(msg || '功能正在开发中...');
	}

	checkboxInit() {
    	if (this.checkbox && this.checkbox.init) {
			// eslint-disable-next-line
    		var initElem = this.checkbox.initElem;
    		if (initElem) {
    			var obj = $(initElem);
			} else {
				// eslint-disable-next-line
				var obj = $('input[type=checkbox]');
			}
    		obj.each(function() {
				$(this).prop('checked', false);
			});
    		$('.checkAll').prop('checked', false);
		}
	}
}

export default View;
