import Base from "../base";
import { Fragment } from 'react';
import TableEmpty from "../../components/tableempty";

class ListLayout extends Base
{
    constructor(props) {
          super(props);

          this.show = props.show !== undefined ? props.show : true; //初始化是否显示
          this.autoload = props.autoload !== undefined ? props.autoload : true; //初始化是否自动加载数据

          var list = props.list || []; // 初始化列表原数组
          var data = (this.dataInit && this.dataInit()) || {};
          data.list = data.list || list;//初始化列表容器

          var state = (this.stateInit && this.stateInit()) || {}; //读取state存储控件初始配置

          state.data = data;//装列表容器载入state
          var search = state.search || {};//初始化筛选条件及分页配置

          if (props.search) {
              for(var k in props.search) {
                  search[k] = props.search[k];
              }
          }

          state.search = search;
          state.listTitles = []; //初始化列表原始容器
          state.listValues = []; //初始化列表数据值容器
          state.searchSwitch = true; //初始化列表搜索控件开关

          this.state = state;

          //初始化搜索控件配置
          this.search = {
              show: props.searchShow !== undefined ? props.searchShow : true,
              fields: props.searchFields || [],
              values: []
          }

          //初始化列表列表配置
          this.list = {
              fields:(this.fieldsInit && this.fieldsInit()) || {},
              visibles:props.visibles || [],
              hidden:props.hidden || [],
              canCheck:props.canCheck !== undefined ? props.canCheck : false,
              checkMany:props.checkMany !== undefined ? props.checkMany : false
          }

          //初始化操作控件配置
          this.opration = {
              show:props.oprationShow !== undefined ? props.oprationShow : true,
              fields:props.oprationFields || []
          }

          //初始化分页配置
          this.paging = {
              show:props.paging !== undefined ? props.paging : true,
              id:props.pageId || 'layPage',
              pageNum:props.pageNum || 1,
              pageSize:props.pageSize || 10,
              runout:props.pagingRunout !== undefined ? props.pagingRunout : false,
              extention: props.pagingExtentionShow !== undefined ? props.pagingExtentionShow : false
          }
          this.paging[this.paging.id] = {
              pageNum:this.paging.pageNum,
              pageSize:this.paging.pageSize
          };

          //初始化填充空表格
          this.empty = {
              fill:props.emptyFill !== undefined ? props.emptyFill : true
          }

          //始始化添加按钮控件配置
          this.btns = {
              show:props.btnShow !== undefined ? props.btnShow : true,
              fields:props.btnFields || []
          }

          // 初始化checkbox配置
          this.checkbox = {
              init:props.checkboxInit !== undefined ? props.checkboxInit : false,
              initElem:props.checkboxInitElem !== undefined ? props.checkboxInitElem : false
          }

          // 初始化样式
          this.style = {
              layuiFluid:props.layuiFluid !== undefined ? props.layuiFluid : true,
              layuiCardBody:props.layuiCardBody !== undefined ? props.layuiCardBody : true,
              layForm:props.layForm !== undefined ? props.layForm : true
          }

          //初始化排序配置
          this.sortShow = {
              props:props.sortShow,
              can: false
          }
    }

    //加载数据
    loadData(conf = {}) {
        let _this = this;
        //自动加载列表
        if (this.show) {
            if (this.autoload) {
                conf.success = (response) => {
                    //组装列表数据
                    _this.setState({
                        data:response.data
                    }, () => {
                        _this.handleList();
                        _this.listInit();
                    })
                }
                this.getList && this.getList(conf);
            } else  {
                //组装列表数据
                _this.handleList();
                _this.listInit();
            }
        }
    }

    getList(conf) {
        return this.getPage(conf);
    }

    setData(data, func) {
        func = func || function () {};
        this.setState({
            data:data
        }, func)
    }

    setList(list, func) {
        func = func || function () {};
        let data = this.state.data;
        data.list = list;
        this.setState({
            data:data
        }, func)
    }

    handleList() {
        if (this.paging.show && this.paging.runout && this.state.data.pages <= 1) {
            this.paging.show = false;
        }
        this.props.handleList && this.props.handleList(this.state.data);
    }

    //初始化标题和数据
    componentWillMount() {
        this.getListTitles();
        this.loadData();
    }

    //组装列表
    listInit() {
        this.getListValues();
    }

    //组装搜索控件
    getSearchFunc() {
        var searches = [],
            config = (this.searchInit && this.searchInit()) || {}, // 读取搜索配置
            searchFields = this.search.fields;

        if (! searchFields.length) {
            for(var k in config) {
                searchFields.push(k);
            }
        }
        if (this.search.show) {
            for(let k in searchFields) {
                let func = config[searchFields[k]],
                    val;
                if (func) {
                    if (typeof func === 'function') {
                        func = func.bind(this);
                        val = func();
                    } else {
                        val = func;
                    }
                    //装载搜索控件
                    searches.push(val);
                }
            }
        }
        return searches;
    }

    getSearchExtentions() {
        var searches = [],
            config = this.searchExtentions ? this.searchExtentions() : [],
            searchFields = this.search.fields;

        for(var k in config) {
            if (searchFields.indexOf(k) < 0) {
                searchFields.push(k);
            }
        }

        if (this.search.show) {
            for(let k in searchFields) {
                var func = config[searchFields[k]],
                    value;
                if (func) {
                    if (typeof func === 'function') {
                        func = func.bind(this);
                        value = func();
                    } else {
                        value = func;
                    }
                    //装载搜索控件
                    searches.push(value);
                }
            }
        }
        return searches;
    }

    //装载配置可显示列表字段
    getVisiblesFields() {
        var fields = this.list.fields,
            visibles = this.list.visibles,
            hidden = this.list.hidden,
            data = [];
        if (! visibles.length) {
            for(let k in fields) {
                visibles.push(k);
            }
        }
        for(let k in visibles) {
            var val = visibles[k];
            if (hidden.indexOf(val) < 0) {
                data.push(val);
            }
        }
        return data;
    }

    // 装载标题
    getListTitles() {
        var fields = this.list.fields,
            visibles = this.getVisiblesFields(),
            titles = [];
        //加载已配置显示字段
        for(var k in visibles) {
            var field = visibles[k];
            if (fields.hasOwnProperty(field)) {
                var value = fields[field],
                    val;
                if (typeof value === 'function') {
                    value = value.bind(this);
                    val = value({})['title'];
                } else {
                    val = value;
                }
                titles.push(val);
            }
        }

        //加载操作
        var opration = this.getOpration({}, true);
        if (opration) {
            titles.push(opration.title);
        }

        //挂载准备好标题
        this.setState({
            listTitles:titles
        })
    }

    //装载列表可显示值
    getListValues() {
        var list = this.state.data.list,
            visibles = this.getVisiblesFields(),
            fieldsArr = this.list.fields,
            values = [];
        for(var index in list) {
            var model = list[index];
            var fields = [];
            for(var k in visibles) {
                var field = visibles[k];
                if (fieldsArr.hasOwnProperty(field)) {
                    var value = fieldsArr[field],
                        v;
                    if (typeof value === 'function') {
                        value = value.bind(this);
                        v = value(model, k)['value'];
                    } else {
                        v = model[field];
                    }
                    v = v && typeof v === 'object' ? v : (<td key={k}>{v}</td>);
                    fields.push(v);
                }
            }
            //加载操作按钮配置
            var opration = this.getOpration(model, false, index);
            if (opration) {
                fields.push(opration.value);
            }
            fields.length && values.push(fields); //将数据依次装载进列表数组
        }

        // 读取可进行排序条件
        var canSort = values.length > 1;
        if (this.sortShow.props !== false) {
            if (typeof this.sortShow.props === 'boolean') {
                if (canSort) {
                    canSort = this.sortShow.props;
                }
            }
            //如果条件可排序并且尚未加载排序,重装标题
            if ((canSort && ! this.sortShow.can) || (! canSort && this.sortShow.can)) {
                this.sortShow.can = canSort;
                this.getListTitles();
            }
        }

        //挂载准备好列表数据
        this.setState({
            listValues:values
        }, () => {
            /* global layui */
            layui.form && layui.form.render();
        })
    }

    //读取操作配置
    getOpration(model, injected = false) {
        if (this.opration.show) {
            if (injected && this.oprationInit && (!model || JSON.stringify(model) === '{}')) {
                var funcString = this.oprationInit.toString();
                var preg = /[oprationInit|function]\(([a-z|A-Z]*?)\)/g;
                var match = funcString.match(preg);
                if (match) {
                    var dependModel = match[0].replace(/[oprationInit|function]\(|\)/g, '');
                    var ppreg = '/'+dependModel+'.[a-z|A-Z]+([(|)]+)/g';
                    var depandPas = funcString.match(this.evil(ppreg));
                    if (depandPas) {
                        for(var k in depandPas) {
                            var v = depandPas[k];
                            let func = v.replace(/[^a-z|A-Z|.]/g, '').split('.');
                            if (! model[func[1]]) {
                                model[func[1]] = () => {};
                            }
                        }
                    }
                }
            }
            var oprations = (this.oprationInit && this.oprationInit(model)) || undefined,
                type = typeof oprations;

            if (type === 'function') {
                return oprations(model);
            } else if (oprations && type === 'object') {
                var obj = {
                    title:oprations.title,
                    value:(<td key={this.generateRandomId()}>{oprations.value}</td>)
                };
                var values = oprations.values,
                    fields = this.opration.fields;
                if (! fields.length) {
                    for(let k in values) {
                        fields.push(k);
                    }
                }
                if (values && typeof values === 'object') {
                    var arr = [];
                   for(let k in fields) {
                       let func = values[fields[k]],val;
                       if (typeof func === 'function') {
                           func = func.bind(this);
                           val = func(model);
                       } else {
                           val = func;
                       }
                       arr.push(val)
                   }
                   if (typeof this.props.oprationExtention === 'function') {
                       arr.push(this.props.oprationExtention(model));
                   }
                   obj.value = (<td key={this.generateRandomId()}>{arr}</td>);
                }
                return obj;
            }
        }
    }

    //读取扩展按钮配置
    getBtnExtentions() {
        if (this.btns.show) {
            var exts = (this.btnExtentions && this.btnExtentions.bind(this) && this.btnExtentions()) || {},
                fields = this.btns.fields,
                btns = [];
            if (! fields.length) {
                for(var k in exts) {
                    fields.push(k);
                }
            }
            for(let k in fields) {
                var func = exts[fields[k]],val;
                if (typeof func === 'function') {
                    func = func.bind(this);
                    val = func();
                } else {
                    val = func;
                }
                if (val) {
                    btns.push(val);
                }
            }
            if (btns.length) {
              return (
                  <div key={this.generateRandomId(10)} style={{paddingBottom: '10px'}}>{btns}</div>
              )
            }
        }
    }

    getPagingExtentions() {
       return this.pagingExtention && this.pagingExtention.bind(this) && this.pagingExtention();
    }

    //接收到props数据时自启动
    componentWillReceiveProps(props) {
        let obj = {};
        if (props.search) {
            obj.search = props.search;
        }
        if (props.data) {
            obj.data = props.data;
        }
        if (props.list) {
            obj.data = this.state.data;
            obj.data.list = props.list;
        }

        if (props.show !== undefined) {
            this.show = props.show;
        }
        if (props.autoload !== undefined) {
            this.autoload = props.autoload;
        }

        if (props.pageSize) {
            this.paging.pageSize = props.pageSize
        }
        if (props.pageNum) {
            this.paging.pageNum = props.pageNum
        }

        if (JSON.stringify(obj) !== '{}') {
            this.setState(state =>{
                return obj;
            }, () => {
                this.willReceiveProps && this.willReceiveProps(props);
                if (props.shouldUpdate !== undefined) {
                    let should = typeof props.shouldUpdate === 'function' ? props.shouldUpdate(this.props, props) : props.shouldUpdate
                    should && this.loadData();
                } else {
                    this.loadData();
                }

            })
        }
    }

    render() {
        return (
            this.show ?
                <div className={this.style.layuiFluid ? "layui-fluid" : ''}>
                    <div className={"layui-card"+(this.style.layForm ? ' layui-form':'')}>
                        {
                            this.search.show ? //筛选表单
                                <div className={"layui-card-header layuiadmin-card-header-auto"}>
                                    <div className={"layui-form-item"}>
                                        {
                                            this.getSearchFunc().map((val, index) => {
                                                return (
                                                    <Fragment key={index}>
                                                        {val}
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                    {this.getSearchExtentions()}
                                </div>
                                :''
                        }
                        <div className={this.style.layuiCardBody ? "layui-card-body" : ''}>
                            {this.getBtnExtentions()}
                            <table cellSpacing="0" cellPadding="0" border="0" className="layui-table" lay-filter="LAY-user-manage">
                                <thead>
                                    <tr className="layui-table-click">
                                        {//标题传成html实体
                                            this.state.listTitles.map((val, index) => {
                                                return (
                                                    <th key={index}>{val}</th>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                {//列表数据展示
                                    this.state.listValues.map((val, index) => {
                                        return (
                                            <tr key={index}>
                                                {
                                                    val.map((v, i) => {
                                                        return <Fragment key={i}>{v}</Fragment>;
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    //填充空表格
                                    <TableEmpty colSpan={this.state.listTitles.length} isShow={this.empty.fill && !Boolean(this.state.listValues.length)} />
                                }
                                </tbody>
                            </table>
                            {//分页
                                this.paging.show ?
                                    <div className="paging">
                                        {this.paging.extention ? this.getPagingExtentions() : ''}
                                        <div className="paging-r">
                                            <div id={this.paging.id}></div>
                                        </div>
                                    </div>
                                    :''
                            }
                            {//表单提交按钮
                                this.list.canCheck ?
                                    <div className="layui-form-item layui-hide">
                                        <input type="button" lay-submit="" lay-filter="LAY-user-front-submit" id="LAY-user-front-submit" value="确认" />
                                    </div>
                                    :''
                            }
                        </div>
                    </div>
                </div>
            :''
        );
    }

    //重载当前页
    pageReload(conf = {}) {
        var paging = this.getPaging();
        conf.pageSize = paging.pageSize;
        conf.pageNum = paging.pageNum;
       this.loadData(conf);
    }

    searchSwitch() {
        this.setState({
            searchSwitch:!this.state.searchSwitch
        })
    }

    generateRandomId(length){ //length是你的id的长度，可自定义
        return Math.random().toString(36).substr(3,length);
    }

    //设置分页
    setPaging(conf) {
        var id = this.paging.id,
        paging = this.paging[id];
        for(var k in conf) {
            paging[k] = conf[k];
        }
        this.paging[id] = paging;
    }

    //获取分页
    getPaging(key) {
        var id = this.paging.id,
            paging = this.paging[id];
        if (key) {
            return paging[key];
        }
        return paging;
    }

    evil(fn) {
        var Fn = Function;  //一个变量指向Function，防止有些前端编译工具报错
        return new Fn('return ' + fn)();
    }
}
export default ListLayout;
