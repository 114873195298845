import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";

class OprationService extends BaseService
{
    requestUrls = {
        url:'/opration/list',
        addOrEditUrl:'/opration/addOrEdit',
        detailUrl:'/opration/detail',
        updateStateUrl:'/opration/updateState',
        updateSortUrl:'/opration/updateSort',
        deleteUrl:'/opration/delete'
    }

    states = ['禁用', '启用'];

    appends = ['stateDup'];

    addOrEdit(model, suc, err) {
        let validator = new Validator(),
            rules = [
                ['name', 'required', '请填写名称'],
                ['link', 'required', '请填写链接']
            ];
        if (! validator.validate(rules, model)) {
            return err(validator.getError());
        }

        this.post(
            this.requestUrls.addOrEditUrl,
            model,
            suc,
            err
        )
    }

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detailUrl,
            {id:id},
            suc
        )
    }

    delete(model, suc, err) {
        this.post(
            this.requestUrls.deleteUrl,
            {id:model.id},
            suc,
            err
        )
    }

    changeState(data, suc, err) {
        let model = {
            id:data.id,
            state:Number(data.state) === 0 ? 1 : 0
        }
        this.post(
            this.requestUrls.updateStateUrl,
            model,
            suc,
            err
        )
    }

    changeSort(data, suc, err) {
        let model = {
            id:data.id,
            sort:data.sort
        }
        this.post(
            this.requestUrls.updateSortUrl,
            model,
            suc,
            err
        )
    }
}
export default OprationService;
