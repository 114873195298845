class Collection
{
    items = [];

    constructor(server, data) {
        this.handleBuilder(server, data);
    }

    handleBuilder(server, data) {
        for(var k in data) {
            this.items[k] = server.makeAttributes(data[k]);
        }
    }

    getItems() {
        return this.items;
    }
}
export default Collection;
