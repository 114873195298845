import View from "../../../../vendor/framework/view";
import Auth from "../../services/auth";
import PermissionMiddleware from "../../middlewares/permission";

class Base extends View
{
    auth = new Auth();

    middleware = [new PermissionMiddleware()];

    componentWillMount() {
        super.componentWillMount();
        if (window.top === window.self) {
            this.app.redirect(this.auth.path, 0);
        }
    }
}
export default Base;