import AdminService from "../../../../services/admin";
import ListLayout from "../../../layout/list";

class AdminListLayout extends ListLayout
{
    stateInit() {
        return {
            search:{
                username:''
            }
        }
    }

    service() {
        return AdminService;
    }

    fieldsInit() {
        return {
            id(val) {
                var inputName = this.props.inputName || 'data[]';
                return {
                    title:this.list.checkMany ? (<span><input type="checkbox" lay-skin="primary" lay-filter="checkAll" />{"ID"}</span>) : 'ID',
                    value:<td>{this.list.canCheck ? <input type="checkbox" name={inputName} lay-skin="primary" lay-filter="ids" className="ids" value={JSON.stringify(val) }/> :
                        <input type="hidden" name={inputName} value={val.id}/>}{val.id}</td>
                }
            },
            username:'管理员姓名',
            roleName:'角色',
            lastLoginAt(model) {
                return {
                    title:'最后登录时间',
                    value:model.lastLoginAt
                }
            },
            createdAt:'添加时间',
            stateDup:'状态'
        }
    }

    btnExtentions() {
        return {
            add() {
                return (
                    <button className="layui-btn" type='button' onClick={e => this.add()}>添加</button>
                )
            }
        }
    }

    searchInit() {
        return {
            name() {
                return (
                    <div className="layui-inline">
                        <label className="layui-form-label">账号</label>
                        <div className="layui-input-inline">
                            <input type="text"  onKeyDown={e => this.onEnter(e, () => {this.loadData()})} name="username" onChange={e => this.onChangeSearch(e)} placeholder="输入帐号" autoComplete="off" value={this.state.search.usrename} className="layui-input" />
                        </div>
                    </div>
                )
            },
            enter() {
                return (
                    <div className="layui-inline">
                        <button className="layui-btn layuiadmin-btn-useradmin" onClick={e => this.loadData()}>
                            <i className="layui-icon layui-icon-search layuiadmin-button-btn" ></i>
                        </button>
                    </div>
                )
            }
        }
    }

    oprationInit(model) {
        return {
            title:'操作',
            values:{
                edit() {
                    return (
                        <button onClick={e => this.edit(model)} className={"layui-btn layui-btn-normal layui-btn-xs" + (! model.canEdit ? ' layui-btn-disabled' : '')}>
                            <i className="layui-icon layui-icon-edit"></i><span>编辑</span>
                        </button>
                    )
                },
                delete() {
                    return (
                        <button onClick={e => this.delete(model)} className={"layui-btn layui-btn-danger layui-btn-xs" + (! model.canDelete() ? ' layui-btn-disabled' : '')}>
                            <i className="layui-icon layui-icon-delete"></i><span>删除</span>
                        </button>
                    )
                },
            }
        }
    }

    componentDidMount() {
        /* global layui,layer */
        layui.use(['form'], () => {

        })
    }

    add() {
        let self = this;
        layer.open({
            title:'添加账号',
            type:2,
            content:'/admin/admin/add',
            area:['80%', '90%'],
            btn:['确定', '取消'],
            yes(index, layero) {
                var iframeWindow = window['layui-layer-iframe'+ index]
                    ,submitID = 'LAY-user-front-submit'
                    // eslint-disable-next-line
                    ,submit = layero.find('iframe').contents().find('#'+ submitID);

                //监听提交
                iframeWindow.layui.form.on('submit('+ submitID +')', function(data){
                    var field = data.field; //获取提交的字段
                    self.server.addOrEdit(field, () => {
                        layer.msg('添加成功!');
                        layer.close(index);
                        self.loadData();
                    }, (msg) => {
                        layer.msg(msg);
                    })
                })
                submit.trigger('click');
            }
        })
    }

    edit(val) {
        let self = this;
        if (val.canEdit) {
            layer.open({
                title:'修改账号 '+val.username,
                type:2,
                content:'/admin/admin/edit/'+val.id,
                area:['80%', '90%'],
                btn:['确定', '取消'],
                yes(index, layero) {
                    var iframeWindow = window['layui-layer-iframe'+ index]
                        ,submitID = 'LAY-user-front-submit'
                        // eslint-disable-next-line
                        ,submit = layero.find('iframe').contents().find('#'+ submitID);

                    //监听提交
                    iframeWindow.layui.form.on('submit('+ submitID +')', function(data){
                        var field = data.field; //获取提交的字段
                        field.id = val.id;
                        self.server.addOrEdit(field, () => {
                            layer.msg('修改成功!');
                            layer.close(index);
                            self.loadData();
                        }, (msg) => {
                            layer.msg(msg);
                        })
                    })
                    submit.trigger('click');
                }
            })
        }
    }

    delete(model) {
        if (model.canDelete()) {
            layer.confirm('您真的要删除 ' + model.username + ' 吗?', () => {
                this.server.delete(model.id, () => {
                    layer.msg('删除成功!');
                    this.pageReload();
                }, (msg) => {
                    layer.msg(msg);
                })
            })
        }
    }
}
export default AdminListLayout;