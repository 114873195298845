import Base from "../../base";
import ProductService from "../../../services/product";
import Form from "../../layout/form";

class ProductEdit extends Base
{
    boot() {
        this.state = {
            model:{}
        }
    }

    service() {
        return ProductService;
    }

    componentDidMount() {
        this.server.getDetail(this.getPropsParams('id'), (res) => {
            this.setModel(res.data);
        })
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={
                    {
                        name: {name: '名称'},
                        keywords:{name:'关键字', type: 'textarea'},
                        desc: {name: '描述', type: 'textarea'},
                        icon: {name: '图标', type: 'image'},
                        content:{name:'内容', type: 'editor'},
                        sort: {name: '排序', type: 'number'},
                        type: {name: '类型', type: 'select', data:this.server.types},
                        state: {name: '状态', type: 'select', data:this.server.states}
                    }
                }
            />
        );
    }
}
export default ProductEdit;
