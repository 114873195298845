import Base from "../../../base";
import ConfigService from "../../../../services/running/config";
import Form from "../../../layout/form";
import { Fragment } from 'react';
import style from './index.module.css';
import $ from 'jquery';

class ConfigList extends Base
{
    boot() {
        this.state = {
            model:{},
            origin:[]
        }
    }

    service() {
        return ConfigService;
    }

    load() {
        this.server.getList((res) => {
            let model = {},
                fields = {};
            for(var k of res.data) {
                model[k.type] = k.value;
                fields[k.type] = {
                    name:k.name,
                    type:k.formType || 'text',
                    data:JSON.parse(k.params || "[]")
                }
            }
            this.setState({
                origin:res.data,
                model:model,
                fields: fields
            })
        })
    }

    componentDidMount() {
        /* global layer */
        this.load();
    }

    render() {
        return (
            <Fragment>
                <Form
                    model={this.state.model}
                    fields={this.state.fields}
                />
                <div onClick={e => this.submit()} className={'layui-btn layui-btn-primary layui-border-blue '+style.btn}>保存</div>
            </Fragment>
        );
    }

    submit() {
        let model = {};
        $('.layui-form').find('input,textarea').each(function() {
            let name = $(this).attr('name');
            if (name) {
                model[name] = $(this).val();
            }
        })
        $('.layui-form').find('select').each(function() {
            let name = $(this).attr('name');
            if (name) {
                model[name] = $(this).val();
            }
        })
        let changes = [];
        for (let k of this.state.origin) {
            if ((model[k.type] || '') !== (k.value || '')) {
                changes.push({type:k.type,value:model[k.type]});
            }
        }

        if (changes.length) {
            for(let k in changes) {
                this.server.edit(changes[k], () => {
                    if (Number(k) === changes.length - 1) {
                        layer.msg('保存成功!');
                        this.load();
                    }
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        } else {
            layer.msg('没有任何修改!');
        }
    }
}
export default ConfigList;
