import ListLayout from "../../layout/list";
import UserService from "../../../services/user";
import $ from "jquery";
import { Fragment } from 'react';

class UserListLayout extends ListLayout
{
    //状态存储初始化
    stateInit() {
        return {
            grades:[],
            search:{
                key:'',
                gradeId:'',
                regDate1:'',
                regDate2:''
            }
        }
    }

    //筛选按钮初始化
    searchInit() {
        return {
            key:() => {
                return (
                    <Fragment>
                        <div className="layui-inline">
                            <label className="layui-form-label">关键字</label>
                            <div className="layui-input-inline">
                                <input type="text" name="key" onKeyDown={e => this.onEnter(e, () => {this.loadData()})} placeholder="请输入用户昵称/手机号/备注" value={this.state.search.key} autoComplete="off" className="layui-input" onChange={e => this.onChangeSearch(e)}/>
                            </div>
                        </div>
                        <div className="layui-inline">
                            <button className="layui-btn layuiadmin-btn-useradmin" onClick={e => this.loadData()} type="button" >
                                <i className="layui-icon layui-icon-search layuiadmin-button-btn" ></i>
                            </button>
                        </div>
                    </Fragment>
                )
            },
            grade:() => {
                return (
                    <div className="layui-inline">
                        <label className="layui-form-label">用户等级</label>
                        <div className="layui-input-inline">
                            <select name="gradeId" value={0} lay-filter={"grade"}>
                                <option value={0}>--全部--</option>
                                {
                                    this.state.grades.map((val, index) => {
                                        return (
                                            <option key={index} value={val.grade}>{val.gradeName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                )
            },
            club:() => {
                return (
                    <div className="layui-inline">
                        <label className="layui-form-label">俱乐部</label>
                        <div className="layui-input-inline">
                            <select name="gradeId" value={""} >
                                <option value={""}>--开发中--</option>
                            </select>
                        </div>
                    </div>
                )
            },
            time:() => {
                return (
                    <div className="layui-inline">
                        <label className="layui-form-label">注册时间</label>
                        <div className="layui-input-inline">
                            <input type="text" name="regDate1" id="date_min" placeholder="开始日期" value={this.getSearch('regDate1')} autoComplete="off" className="layui-input" />
                        </div>
                        <div className="layui-form-mid layui-word-aux">-</div>
                        <div className="layui-input-inline">
                            <input type="text" name="regDate2" id="date_max" placeholder="结束日期" value={this.getSearch('regDate2')} autoComplete="off" className="layui-input" />
                        </div>
                    </div>
                )
            }
        }
    }

    //数据字段初始化
    fieldsInit() {
        return {
            id:(val = {}) => {
                var inputName = this.props.inputName || 'data[]';
                return {
                    title:this.list.checkMany ? (<span><input type="checkbox" lay-skin="primary" className={"checkAll"} lay-filter="checkAll" />{"ID"}</span>) : 'ID',
                    value:<td>{this.list.canCheck ? <input type="checkbox" name={inputName} lay-skin="primary" lay-filter="ids" className="ids" value={JSON.stringify(val) }/> :
                        <input type="hidden" name={inputName} value={val.id}/>}{val.id}</td>
                }
            },
            headPortrait:(val = {}) => {
                return {
                    title:'头像',
                    value:<td><img alt={val.name+'的头像'} className={"tsimg psimg"} src={val.headPortrait} /></td>
                }
            },
            name:'用户昵称',
            mobile:'手机号',
            gradeTxt:'用户等级',
            integral:'积分',
            isExtensionAgentDup:'是否为推广员',
            sexTxt:'性别',
            area:'所在地区',
            online:"登录总时长",
            addTime:'注册时间',
            remark:'备注'
        }
    }

    //操作初始化
    oprationInit() {
        return {
            title:'操作',
            values:{
                detail:(model) => {
                    return (
                        <button onClick={e => this.userDetail(model)} className="layui-btn layui-btn-normal layui-btn-xs">
                            <i className="layui-icon layui-icon-list"></i><span>查看详情</span>
                        </button>
                    )
                },
                setExtensionAgent:(model = {}) => {
                    return (
                        <button onClick={e => this.setPromoter(model)} className="layui-btn layui-btn-danger layui-btn-xs">
                            <i className="layui-icon layui-icon-auz"></i><span>{!model.isExtensionAgent ? '设为' :'解除'} 推广员</span>
                        </button>
                    )
                },
                follow:(model) => {
                    return (
                        <button className="layui-btn layui-btn-normal layui-btn-xs" onClick={e => this.developing()}>
                            <i className="layui-icon layui-icon-auz"></i><span>跟进</span>
                        </button>
                    )
                },
                remove:(model) => {
                    return (
                        <button onClick={e => this.remove(model)} className="layui-btn layui-btn-xs layui-btn-danger">
                            <i className="layui-icon layui-icon-delete"></i>移除
                        </button>
                    )
                }
            }
        }
    }

    //启动服务
    service() {
        return UserService;
    }

    //获取列表数据并初始化列表结构
    getList(conf = {}) {
        this.server.getList(conf)
    }

    //组件挂载完毕
    componentDidMount() {
        /* global layui,layer */
        let self = this;
        layui.use(['laydate', 'form', 'laypage'], ()  =>{
            var form = layui.form,
                laydate = layui.laydate;

            //日期范围选择
            laydate.render({
                elem: '#date_min'
                ,type: 'date'
                ,done: function(value, date) {
                    var end = $('#date_max').val();
                    if (value && (value > end) && end) {
                        layer.msg('开始时间不能大于结束时间');
                        value = '';
                    }
                    self.setSearch('regDate1', value);
                    self.loadData();
                }
            });

            //日期范围选择
            laydate.render({
                elem: '#date_max'
                ,type: 'date'
                ,done: function(value, date) {
                    var start= $('#date_min').val();
                    if (value && (value < start)  && start) {
                        layer.msg('结束时间不能小于开始时间');
                        value = '';
                    }
                    self.setSearch('regDate2', value);
                    self.loadData();
                }
            });

            form.on('checkbox(ids)', function(data) {
                let checked = data.elem.checked;
                $(data.elem).prop('checked', checked);
                if (!self.list.checkMany && checked) {
                    $(data.elem).closest('tr').siblings().find('.ids').each(function() {
                        $(this).prop('checked', !checked);
                    })
                }
                form.render('checkbox');
            })

            form.on('checkbox(checkAll)', function(data) {
                $('.ids').each(function(){
                    $(this).prop("checked",data.elem.checked);
                })
                form.render('checkbox')
            });

            form.on('select(grade)', (data) => {
                self.setSearch('gradeId', data.value);
                self.loadData();
            })
        })
    }

    //用户详情
    userDetail(user) {
        layui.use('layer', () => {
            var layer = layui.layer;
            var layIndex = layer.open({
                type: 2
                ,title: user.name + ' 用户详情'
                ,content: '/admin/user/detail/'+user.id
                ,maxmin: true,
                min() {
                    $('#layui-layer-shade'+layIndex).hide();
                },
                max() {
                    $('#layui-layer-shade'+layIndex).show();
                },
                restore() {
                    $('#layui-layer-shade'+layIndex).show();
                }
                ,area: ['80%', '90%']
                ,btn: ['确定']
                ,yes: function(index, layero){
                    layer.close(index);
                }
            });
        })
    }

    //设置推广员
    setPromoter(user) {
        let name = user.name || '他/她',
            title = user.isExtensionAgent ? ('解除 ' + name +' 的推广员身份') : ('设 ' + name + ' 为推广员'),
            self = this;
        layer.confirm('您真的要'+title+'吗?', () => {
            self.server.setPromoter(user, () => {
                layer.msg('设置成功!');
                self.pageReload();
            }, (msg) => {
                layer.msg(msg)
            });
        })
    }

    remove(model) {
        this.props.remove && this.props.remove(model);
    }
}
export default UserListLayout;
