import Base from "../../../../base";

class AdminInformationEdit extends Base
{
    boot() {
        this.state = {
            model:{
                oldPassword :'',
                newPassword:'',
                passwordConfirmation:''
            }
        }
    }

    componentDidMount() {
        /* global layui */
        layui.use(['form'], () => {

        })
    }

    render() {
        return (
            <div className="layui-form" lay-filter="layuiadmin-form-useradmin" id="layuiadmin-form-useradmin"
                 style={{padding: '20px 30px 0 0'}}>
                <div className="layui-form-item">
                    <label className="layui-form-label">原密码</label>
                    <div className="layui-input-block">
                        <input type="password" onChange={e => this.onChangeModel(e)} value={this.state.model.oldPassword} name="oldPassword" autoComplete="off"
                               className="layui-input" />
                    </div>
                </div>
                <div className="layui-form-item">
                    <label className="layui-form-label">新密码</label>
                    <div className="layui-input-block">
                        <input type="password" onChange={e => this.onChangeModel(e)} value={this.state.model.newPassword} name="newPassword" autoComplete="off"
                               className="layui-input" />
                    </div>
                </div>
                <div className="layui-form-item">
                    <label className="layui-form-label">重复新密码</label>
                    <div className="layui-input-block">
                        <input type="password" name="newPasswordConfirmation" autoComplete="off"
                               className="layui-input" onChange={e => this.onChangeModel(e)} value={this.state.model.newPasswordConfirmation} />
                    </div>
                </div>
                <div className="layui-form-item layui-hide">
                    <input type="button" lay-submit={""} lay-filter="LAY-user-front-submit" id="LAY-user-front-submit"
                           value="确认" />
                </div>
            </div>
        );
    }
}
export default AdminInformationEdit;