import ListLayout from "./list";
import {Fragment} from "react";
import $ from 'jquery';
import style from './list.module.css'

class Table extends ListLayout {

    stateInit() {
        let state = this.props.stateInit || {},
            search = this.props.searchInit || {};
        state.search = {};
        for (var k in search) {
            state.search[k] = search[k].value;
        }
        return state;
    }

    fieldsInit() {
        let fields = this.props.fieldsInit || {},
            fieldContainer = {};

        for (let k in fields) {
            let item = fields[k],
                type = item.type || 'text',
                func = this['field' + type.charAt(0).toUpperCase() + type.slice(1)].bind(this),
                itemType = typeof item;
            fieldContainer[k] = (itemType === 'string' || itemType === 'function') ? item : (model = {}) => {
                return func(model, k, item, k);
            }
        }
        return fieldContainer;
    }

    fieldText(model, name, item, i) {
        if (item.checkbox) {
            var inputName = this.props.inputName || 'data[]';
            return {
                title: this.list.checkMany ? (<span><input type="checkbox" lay-skin="primary"
                                                           lay-filter="checkAll"/>{item.name}</span>) : item.name,
                value: <td key={i}>{this.list.canCheck ?
                    <input type="checkbox" name={inputName} lay-skin="primary" lay-filter={item.filter || "ids"}
                           className={item.filter || "ids"}
                           value={JSON.stringify(model)}/> :
                    <input type="hidden" name={inputName} value={model.id}/>}{model.id}</td>
            }
        }
        return {
            title: item.name,
            value: <td style={item.style || {}}>{model[name]}</td>
        }
    }

    fieldInput(model, name, item, i) {
        return {
            title: item.name,
            value: (
                <td key={i} style={{width: '100px'}}>
                    <input type="number" value={model[name]}
                           onBlur={e => this.changeInput(name, model, item)}
                           onChange={e => this.onChangeInput(e, name, model)}
                           className="layui-input"/>
                </td>
            )
        }
    }

    fieldSwitch(model, name, item, i) {
        return {
            title: item.name,
            value: (
                <td key={i} width="6%">
                    <input type="checkbox" value={model[name]} readOnly={true} checked={item.checked === model[name]}
                           data-id={model.id} lay-filter={'field' + name} lay-skin="switch" lay-text={item.text}/>
                </td>
            )
        }
    }

    fieldImage(model, name, item, i) {
        return {
            title:item.name,
            value:(
                <td key={i}><div className={"tsimgbox"}><img  alt={""} className={"psimg"} src={model[name]} /></div></td>
            )
        }
    }

    searchInit() {
        let search = this.props.searchInit || {},
            searchContainer = {};
        for (let k in search) {
            let item = search[k];
            if (typeof item === 'string') {
                searchContainer[k] = () => {
                    return this.searchText(k, item);
                }
            } else {
                let type = search[k].type || 'text',
                    func = this['search' + type.charAt(0).toUpperCase() + type.slice(1)].bind(this);
                searchContainer[k] = () => {
                    return func(k, search[k], search);
                }
            }

        }
        return searchContainer;
    }

    searchText(name, item) {
        let title = typeof item === 'string' ? item : item.name;
        return (
            <Fragment>
                <div className="layui-inline">
                    <label className="layui-form-label">{title}</label>
                    <div className="layui-input-block">
                        <input type="text" onKeyDown={e => this.onEnter(e, () => {
                            this.loadData()
                        })} name={name} onChange={e => this.onChangeSearch(e)} placeholder={"输入" + title}
                               autoComplete="off" value={this.state.search[name]} className="layui-input"/>
                    </div>
                </div>
                {
                    item.enter ?
                        (
                            <div className="layui-inline">
                                <button className="layui-btn layuiadmin-btn-useradmin" onClick={e => this.loadData()}>
                                    <i className="layui-icon layui-icon-search layuiadmin-button-btn"></i>
                                </button>
                            </div>
                        )
                        : ''
                }
            </Fragment>
        )
    }

    searchSelect(name, item) {
        let def = item.default,
            value = this.state.search[name],
            option = '';
        name = item.key || name;
        if (item.hasOwnProperty('default')) {
            if (typeof def !== 'object') {
                value = def;
                option = (
                    <option value={def}>--全部--</option>
                )
            } else {
                if (item.option) {
                    for(let k of item.data) {
                        if (k[option[0]] === def.value) {
                            option = (
                                <option value={def.value}>{def.name}</option>
                            )
                        }
                    }
                }
            }
        }

        return (
            <div className="layui-inline">
                <label className={"layui-form-label "+style['layui-form-label'] + ' '+(item.layTip ? 'layTip' : '')} title={item.name}>{item.name}</label>
                <div className="layui-input-inline">
                    <select name={name} lay-filter={"search" + name} defaultValue={value} value={this.state.search[name]}>
                        {
                            option
                        }
                        {
                            item.data instanceof Array ? this.searchSelectFromArray(item) : this.searchSelectFromObject(item)
                        }
                    </select>
                </div>
            </div>
        )
    }

    searchSelectFromArray(item) {
        return item.data.map((val, index) => {
            return (
                <option key={index}
                        value={item.option ? val[item.option[0]] : index}>{item.option ? val[item.option[1]] : val}</option>
            )
        })
    }

    searchSelectFromObject(item) {
        return Object.keys(item.data || {}).map((key, index) => {
            return (
                <option key={index} value={key}>{item.data[key]}</option>
            )
        })
    }

    searchDate(name, item, search) {
        let num = item.position,
            end;
        for(let k in search) {
            let prev = search[k];
            if (typeof prev === 'object' && prev.type === 'date' && (prev.position = num - 1)) {
                return;
            }
            if (typeof prev === 'object' && prev.type === 'date' && (prev.position = num + 1)) {
                end = this.searchEndDate(k, prev);
            }
        }
        return (
            <div className="layui-inline">
                <label className="layui-form-label">{item.name}</label>
                <div className="layui-input-inline">
                    <input type="text" name={name} placeholder={item.placeholder || '开始日期'}
                           id={name} autoComplete="off" className="layui-input"
                           value={this.state.search[name]}/>
                </div>
                {end}
            </div>
        )
    }

    searchEndDate(name, item) {
        return (
            <Fragment>
                <div className="layui-form-mid layui-word-aux">-</div>
                <div className="layui-input-inline">
                    <input type="text" name={name} placeholder={item.placeholder || "结束日期"} id={name}
                           autoComplete="off" className="layui-input" value={this.state.search[name]}/>
                </div>
            </Fragment>
        )
    }

    oprationInit() {
        let opration = this.props.oprationInit || {},
            oprationContainer = {
                title: '操作',
                values: {}
            };
        for (let k in opration) {
            let item = opration[k],
                icon = item.icon || [];
            oprationContainer.values[k] = (model = {}) => {
                return (
                    <button onClick={e => item.click && item.click(model, this)}
                            className={"layui-btn layui-btn-" + (typeof icon === 'string' ? 'normal' : (icon[1] || 'normal')) + " layui-btn-xs" + (item.disabled ? (!model[item.disabled] ? ' layui-btn-disabled' : '') : '')}>
                        <i className={"layui-icon layui-icon-" + (typeof icon === 'string' ? icon : icon[0])}></i><span>{item.name}</span>
                    </button>
                )
            }
        }
        return oprationContainer;
    }

    btnExtentions() {
        let btns = this.props.btnInit || {},
            btnContainer = {};
        for (let k in btns) {
            let item = btns[k];
            btnContainer[k] = () => {
                return (
                    <button className="layui-btn" type='button'
                            onClick={e => item.click && item.click(this)}>{item.name}</button>
                )
            }
        }
        return btnContainer;
    }

    service() {
        return this.props.service;
    }

    componentDidMount() {
        /* global layui,layer */
        let modules = ['form'],
            types = ['select', 'switch'],
            fields = this.props.fieldsInit || {},
            search = this.props.searchInit || {},
            _this = this;
        for(var k in search) {
            var item = this.props.searchInit[k];
            if (typeof item === 'object' && item.type === 'date' && modules.indexOf('laydate') === -1) {
                modules.push('laydate');
            }
        }

        layui.use(modules, () => {
            let form = layui.form,
                laydate;

            if (modules.indexOf('laydate') > -1) {
                laydate = layui.laydate;
            }


            for (let k in fields) {
                let item = fields[k];
                if (item && typeof item === 'object' && typeof item.change === 'function' && types.indexOf(item.type) > -1) {
                    form.on(item.type + '(field' + k + ')', (data) => {
                        var id = $(data.elem).attr('data-id');
                        !_this.autoload ? item.change({id: id, [k]: data.value}, _this) : item.change({id: id, [k]: data.value}, () => {
                            layer.msg('修改成功');
                            this.pageReload();
                        }, (msg) => {
                            layer.msg(msg);
                            this.pageReload();
                        })
                    })
                }
            }

            let dates = [];
            for (let k in search) {
                let item = search[k];
                if (item && typeof item === 'object' && item.type) {
                    if (types.indexOf(item.type) > -1) {
                        form.on(item.type+'(search'+k+')', (data) => {
                            this.state.search[k] = data.value;
                            this.loadData();
                        })
                    }
                    if (item.type === 'date') {
                        dates.push({name:k,item:item});
                    }
                }
            }

            if (dates.length) {
                setTimeout(() => {
                    for(let k of dates) {
                        laydate.render({
                            elem: '#'+k.name
                            ,type: k.item.format
                            ,done: function(value) {
                                _this.state.search[k.name] = value;
                                _this.loadData();
                            }
                        })
                    }
                }, 1000)
            }
        })
    }

    onChangeInput(e, name, model) {
        let origin = 'originchangeinput' + name;
        if (model[origin] === undefined) {
            model[origin] = model[name];
        }
        model[name] = e.target.value;
        this.listInit();
    }

    changeInput(name, model, item) {
        let origin = 'originchangeinput' + name;
        if (String(model[origin]) && model[origin] !== undefined && String(model[name]) !== String(model[origin]) && typeof item.change === 'function') {
            ! this.autoload ? item.change(model, this) : item.change(model, () => {
                layer.msg('修改成功');
                model[origin] = model[name];
                this.listInit();
            }, (msg) => {
                layer.msg(msg);
                this.pageReload();
            })
        }
    }

    componentWillReceiveProps(props) {
        super.componentWillReceiveProps(props);
    }
}

export default Table;
